import { Checkbox, Layout } from 'antd';
import { HeaderRef, RowItem } from '../../../types';
import { useProjectContext } from '../../../context/ProjectContext';
import Empty from '../../global/Empty';
import { ProjectTags } from '../components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import parse from 'html-react-parser';
import { HeadersType } from './PatternTab';
import { pjctNavWidth, pjctNavWidthSmall } from '../../../utils/constants';

const { Content } = Layout;

type RowsProps = {
  toggleDone?: (i: number) => void;
  headers: HeadersType;
  collapsed: boolean;
};

export default function RowsDisplay({ toggleDone, headers, collapsed }: RowsProps) {
  const project = useSelector((state: RootState) => state.project.project);
  const { activeTab } = useProjectContext();

  const isPattern = activeTab === 'pattern';
  if (!project) return <>no project</>;

  const handleToggle = (i: number) => {
    const type = project[activeTab][i]?.type;
    if (type === 'row' && toggleDone) {
      toggleDone(i);
    }
  };

  // ROW VALUE
  const getRowValue = (row: RowItem) => {
    switch (row.type) {
      case 'image':
        return (
          <div className="row-image" style={{ width: `${row.width || 100}%` }}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/tmdhcily.appspot.com/o/${row.value.replaceAll('/', '%2F')}?alt=media`} alt="" loading="lazy" />
          </div>
        );

      case 'header':
        const header = headers.all.find(h => h.id === row.id);
        const ref: HeaderRef | null = header ? (headers.refs[header?.refId] as HeaderRef) : null;

        return <h2 ref={ref}>{parse(row.value)}</h2>;
      default:
        return <span className={getValueClasses(row)}>{parse(row.value)}</span>;
    }
  };

  const getValueClasses = (row: RowItem) => {
    let classes = 'row-value';
    if (row.done) {
      classes = `${classes} done`;
    }
    if (row.type !== 'row' || (row.type === 'row' && !row.label)) {
      classes = `${classes} no-label`;
    }
    return classes;
  };
  const hasHeaders = headers.all.length > 0;

  let marginInlineStart = 0;
  if (hasHeaders) {
    marginInlineStart = collapsed ? pjctNavWidthSmall : pjctNavWidth;
  }

  const noRows = project[activeTab].length < 1;
  return (
    <Layout style={{ marginInlineStart, transition: '250ms ease all', background: '#fff' }}>
      <Content>
        {/* TAGS */}
        {activeTab === 'specs' && <ProjectTags />}

        {noRows ? (
          // EMPTY
          <Empty msg={`No ${activeTab} added yet`} />
        ) : (
          // ROWS
          project[activeTab].map((row: RowItem, i: number) => {
            const isPatternRow = row.type === 'row';
            return (
              <div className={`row-display ${isPatternRow ? 'hover' : ''}`} key={i} onClick={() => handleToggle(i)}>
                {isPattern && row.type === 'row' && <Checkbox className="is-done-cb" checked={row.done} onClick={() => handleToggle(i)} />}

                {row.type === 'row' && row.label && (
                  <span className={`row-label ${row.done ? 'done' : ''}`} style={{ fontWeight: 600 }}>
                    {row.label}
                  </span>
                )}

                {getRowValue(row)}
              </div>
            );
          })
        )}
      </Content>
    </Layout>
  );
}
