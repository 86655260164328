import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthUser, Folder, Project, ResponseData } from '../../types';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';

interface ApiCall {
  user?: AuthUser | null;
  type: 'add' | 'save';
}
interface ProjectCall extends ApiCall {
  project: Project;
}
interface FolderCall extends ApiCall {
  folder: Folder;
}

const handlePromise = async ({ user, updatedUser }: { user?: AuthUser | null; updatedUser: AuthUser }) => {
  if (user) {
    try {
      await setDoc(doc(db, 'users', user.id), updatedUser);
      return Promise.resolve();
    } catch (e) {
      console.log({ error: e });

      return Promise.reject();
    }
  }
  return Promise.reject();
};

export const onUpdateProject = createAsyncThunk('/project/onSaveProject', async ({ project, user, type }: ProjectCall) => {
  const _user = { ...user } as AuthUser;
  if (type === 'add') {
    _user.allProjects = [..._user.allProjects, project];
  } else if (type === 'save') {
    _user.allProjects = _user.allProjects.map((p: Project) => {
      if (p.id === project.id) {
        return { ...project, lastModified: `${new Date()}` };
      }
      return p;
    });
  }
  return handlePromise({ user, updatedUser: _user });
});

export const onUpdateFolder = createAsyncThunk('/project/onAddFolder', async ({ folder, user, type }: FolderCall) => {
  const _user = { ...user } as AuthUser;
  switch (type) {
    case 'add':
      _user.allFolders = [..._user.allFolders, folder];
      break;
    case 'save':
      _user.allFolders = _user.allFolders.map((f: Folder) => {
        if (f.id === folder.id) {
          return { ...folder, lastModified: `${new Date()}` };
        } else {
          return f;
        }
      });
      break;
  }
  return handlePromise({ user, updatedUser: _user });
});

export const onDeleteItem = createAsyncThunk('/project/onDeleteItem', async ({ id, isFolder, userData }: { id: string; isFolder?: boolean; userData: ResponseData | null }) => {
  if (!userData) return Promise.reject();

  const { allProjects, allFolders, user } = userData;
  const _user = { ...user } as AuthUser;

  let projectsInFolder: Project[] = [];
  if (isFolder) {
    projectsInFolder = allProjects.filter((p: Project) => p.folder === id);
  }

  if (projectsInFolder?.length) {
    // delete folder and projects within
    const projIds = projectsInFolder.map((p: Project) => p.id);
    const updatedFolders = [...allFolders].filter((f: Folder) => f.id !== id);
    const updatedProjects = [...allProjects].filter((p: Project) => !projIds.includes(p.id));
    _user.allFolders = updatedFolders;
    _user.allProjects = updatedProjects;
  } else {
    // delete single item
    if (isFolder) {
      _user.allFolders = _user.allFolders.filter((f: Folder) => f.id !== id);
    } else {
      _user.allProjects = _user.allProjects.filter((p: Project) => p.id !== id);
    }
  }

  return handlePromise({ user, updatedUser: _user });
});
