import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { editActionsHeight, sideNavWidth, totalHeight } from '../utils/constants';
import usePageLocation from '../utils/hooks/usePageLocation';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../state/store';
import { setLoading, setProjectStore } from '../state/project/project';
import { message as AntDMessage } from 'antd';
import { NoticeType } from 'antd/es/message/interface';
import { TabType } from '../types';

type ProjectContextType = {
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
  setIsTimerOn: Dispatch<SetStateAction<boolean>>;
  isTimerOn: boolean;
  setActiveTab: Dispatch<SetStateAction<TabType>>;
  activeTab: TabType;
  siderWidth: number;
  toggleEditMode: () => void;
};

const initialState: ProjectContextType = {
  setEditMode: () => {},
  toggleEditMode: () => {},
  setIsTimerOn: () => {},
  setActiveTab: () => {},
  activeTab: 'pattern',
  isTimerOn: false,
  editMode: false,
  isMobile: false,
  siderWidth: sideNavWidth,
};

const ProjectContext = createContext<ProjectContextType | undefined>(initialState);

export const ProjectContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [messageApi, msgContextHolder] = AntDMessage.useMessage();
  let { projectId } = useParams();
  const userData = useSelector((state: RootState) => state.auth.userData);
  const message = useSelector((state: RootState) => state.project.message);
  const dispatch = useDispatch<AppDispatch>();

  const { onAdd } = usePageLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const isEditMode = searchParams.get('edit') === 'true';
  const [editMode, setEditMode] = useState<boolean>(isEditMode);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isTimerOn, setIsTimerOn] = useState(false);
  const [siderWidth, setSiderWidth] = useState(sideNavWidth);
  const [activeTab, setActiveTab] = useState<TabType>('pattern');

  // SET PROJECT
  useLayoutEffect(() => {
    dispatch(setLoading(true));
    if (projectId) {
      dispatch(setProjectStore({ id: projectId, allProjects: userData?.allProjects || [] }));
    } else if (onAdd) {
      dispatch(setProjectStore({ allProjects: userData?.allProjects || [] }));
    }
  }, [projectId, onAdd, dispatch, userData?.allProjects]);

  // EDIT MODE
  useLayoutEffect(() => {
    if (searchParams.get('edit') === 'true') {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [searchParams]);

  // ALERT MESSAGE
  const onMessage = useCallback(
    (type: NoticeType, msg: string) => {
      messageApi?.open({
        type,
        content: msg,
      });
    },
    [messageApi],
  );

  // ON SUCCESS or ERROR
  useEffect(() => {
    if (message) {
      onMessage(message.type, `${message.text}`);
    }
  }, [message, onMessage]);

  // IS MOBILE
  const isMobileCallback = () => {
    const isMobile = window.innerWidth <= 768;
    if (window.innerHeight !== windowHeight) {
      setWindowHeight(window.innerHeight);
    }
    setIsMobile(isMobile);
    setSiderWidth(isMobile ? 0 : sideNavWidth);
  };

  const toggleEditMode = () => {
    if (!editMode) {
      setSearchParams({
        ...searchParams,
        edit: 'true',
      });
      console.log({ h: window.innerHeight - totalHeight });
    } else {
      setSearchParams({
        ...searchParams,
      });
      console.log({ h: window.innerHeight - totalHeight + editActionsHeight });
    }
  };

  const observer = new ResizeObserver(isMobileCallback);
  observer.observe(document.body);

  const contextValue = {
    editMode,
    setEditMode,
    isMobile,
    toggleEditMode,
    isTimerOn,
    setIsTimerOn,
    siderWidth,
    activeTab,
    setActiveTab,
  };

  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
      {msgContextHolder}
    </ProjectContext.Provider>
  );
};

export function useProjectContext(): ProjectContextType {
  const context = useContext(ProjectContext) as ProjectContextType;
  if (context === undefined) {
    throw new Error('context is undefined');
  }
  return context;
}
