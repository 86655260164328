import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { db } from '../../firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import { AuthUser, Folder, Project, ResponseData } from '../../types';
import { setAllTags, setData } from './auth';
import { resetUpdateData } from '../project/project';

export const userDataApi = createApi({
  reducerPath: 'userDataApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Post'],
  endpoints: builder => ({
    getUserData: builder.query({
      queryFn: async arg => {
        if (!localStorage.getItem('authToken')) {
          return { data: null };
        }

        if (localStorage.getItem('data') && !arg.forceRefetch) {
          const localData = JSON.parse(`${localStorage.getItem('data')}`);
          if (localData) {
            return { data: localData };
          }
        }

        // CALL DATABASE
        console.log('fetching');

        const userId = localStorage.getItem('uid');
        const userQuery = query(collection(db, 'users'));
        const allUserDocs = await getDocs(userQuery);
        const allUsers: AuthUser[] = [];
        allUserDocs.forEach(a => {
          const data: AuthUser = { ...a.data() } as AuthUser;
          allUsers.push(data);
        });

        let authUser: AuthUser | null = null;
        let allProjects: Project[] = [];
        let allFolders: Folder[] = [];

        const foundUser: AuthUser | undefined = allUsers.find((u: AuthUser) => u.id === userId);

        if (foundUser) {
          authUser = foundUser;
          allProjects = foundUser.allProjects;
          allFolders = foundUser.allFolders;
        }
        const data: ResponseData = { user: authUser, allProjects, allFolders };

        return { data };
      },
      onCacheEntryAdded: async (arg, { cacheDataLoaded, dispatch, getCacheEntry }) => {
        await cacheDataLoaded;
        const cache = getCacheEntry();

        const { data } = cache;
        const strData = JSON.stringify(data);
        localStorage.setItem('data', strData);

        dispatch(setData(data));
        dispatch(setAllTags(data));
        dispatch(resetUpdateData());
      },
    }),
  }),
});

export const { useGetUserDataQuery } = userDataApi;
