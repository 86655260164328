import { EditorProvider, useCurrentEditor } from '@tiptap/react';
import { RowItem } from '../../../../types';
import StarterKit from '@tiptap/starter-kit';
import { Button, ButtonProps, Flex } from 'antd';
import BoldIcon from '@mui/icons-material/FormatBold';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ArrowElbowDownRight, TextHTwo, TextItalic } from '@phosphor-icons/react';

type RowValueInputProps = {
  row: RowItem;
  index: number;
  handleRowChangeFormat: (i: number, s: string) => void;
  setCurrFocus: (n: number) => void;
  currFocus: number;
};

const extensions = [StarterKit.configure({})];

const Tools = ({ row, index, handleRowChangeFormat, setCurrFocus, currFocus }: RowValueInputProps) => {
  const { editor } = useCurrentEditor();
  const [open, setOpen] = useState(false);
  const focusIndex = useRef(currFocus);

  useEffect(() => {
    focusIndex.current = currFocus;
  }, [currFocus]);

  useEffect(() => {
    editor?.commands.setContent(row.value);
  }, [row.value]);

  if (!editor) {
    return null;
  }

  editor.on('focus', () => {
    setCurrFocus(index);
    setOpen(true);
  });

  editor.on('update', ({ editor }) => {
    handleRowChangeFormat(index, editor.getHTML());
  });

  editor.on('blur', ({ editor }) => {
    setTimeout(() => {
      if (focusIndex.current !== index) {
        setOpen(false);
      }
    }, 100);
  });

  const btnProps: ButtonProps = { shape: 'circle', size: 'small', type: 'text' };
  return (
    <Flex className={`format-tools ${open ? 'open' : ''}`} align="center">
      <Button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        style={{ opacity: editor.isActive('bold') ? 1 : 0.6 }}
        icon={<BoldIcon />}
        {...btnProps}
      />
      <Button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        style={{ opacity: editor.isActive('italic') ? 1 : 0.6 }}
        icon={<TextItalic size={18} weight="bold" />}
        {...btnProps}
      />
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        style={{ opacity: editor.isActive('heading', { level: 3 }) ? 1 : 0.6 }}
        icon={<TextHTwo size={18} weight="bold" />}
        {...btnProps}
      />
      <Button onClick={() => editor.chain().focus().setHardBreak().run()} icon={<ArrowElbowDownRight size={18} weight="bold" />} {...btnProps} />
    </Flex>
  );
};

export default function RowValueInput({ row, index, handleRowChangeFormat, setCurrFocus, currFocus }: RowValueInputProps) {
  const props = { row, index, handleRowChangeFormat, setCurrFocus, currFocus };

  const content = useMemo(() => {
    return row.value;
  }, [row]);

  return (
    <div className={`text-editor row-value ${row.type !== 'row' ? 'no-label' : ''}`}>
      <EditorProvider slotBefore={<Tools {...props} />} content={content} extensions={extensions}></EditorProvider>
    </div>
  );
}
