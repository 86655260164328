import { Article, Plus, PlusCircle, Trash } from '@phosphor-icons/react';
import { Button, Dropdown, Flex, MenuProps, Modal, Switch } from 'antd';
import { RowItem, RowType, TabType } from '../../../types';
import { Dispatch, memo, SetStateAction, useState } from 'react';
import CustomDropdown from '../../global/CustomDropdown';
import { useProjectContext } from '../../../context/ProjectContext';
import { PatternCompiler } from '../components';

export const addActions: RowType[] = ['row', 'note', 'header', 'image'];
export const addIcon = <Plus size={12} weight="bold" />;
export const addRowName = (activeTab: TabType) => {
  let addRowName = activeTab.substring(0, activeTab.length - 1);
  if (activeTab === 'pattern') {
    addRowName = 'row';
  } else if (activeTab === 'abbreviations') {
    addRowName = 'abbr';
  }
  return addRowName;
};

export type EditorActionsProps = {
  deleteMode: boolean;
  setDeleteMode: Dispatch<SetStateAction<boolean>>;
  addNewRow: (type?: RowType) => void;
  onConvert: (r: RowItem[]) => void;
};
function EditorActions({ addNewRow, setDeleteMode, deleteMode, onConvert }: EditorActionsProps) {
  const { activeTab } = useProjectContext();
  const [openModal, setOpenModal] = useState(false);

  const addItems: MenuProps['items'] = addActions.map((action: RowType) => {
    const isRow = action === 'row';
    return {
      key: action,
      label: (
        <Button onClick={() => addNewRow(action)} icon={addIcon} type="primary" size="small">
          {isRow ? addRowName(activeTab) : action}
        </Button>
      ),
    };
  });
  const deleteIcon = <Trash size={18} weight="bold" style={{ marginTop: deleteMode ? '2px' : '' }} />;
  return (
    <Flex className={`editor-actions`} align="center" justify="end">
      <Dropdown
        dropdownRender={() => {
          return (
            <CustomDropdown>
              <div className="add-action-menu">
                {addItems.map((item: any) => {
                  return (
                    <div key={item.key} className="add-action-btn">
                      {item.label}
                    </div>
                  );
                })}
              </div>
            </CustomDropdown>
          );
        }}
      >
        <Button icon={<PlusCircle size={18} weight="bold" />}>Add</Button>
      </Dropdown>

      <Button onClick={() => setOpenModal(true)} icon={<Article size={18} weight="fill" />}>
        Paste in pattern
      </Button>

      <Switch checkedChildren={deleteIcon} unCheckedChildren={deleteIcon} onChange={() => setDeleteMode(!deleteMode)} style={{ background: deleteMode ? '#ff4d4f' : '' }} />
      {openModal && (
        <Modal title={<h2>Paste text pattern below</h2>} open footer={[]} closeIcon={null} width={700} style={{ maxHeight: '300px' }}>
          <span>Each line will be considered its own input row</span> <br />
          <span>
            <strong>NOTE:</strong> add a colon ":" between the label and value to all lines you want to be a{activeTab === 'pattern' ? ' ROW' : 'n ABBR'}
          </span>
          <PatternCompiler closeModal={() => setOpenModal(false)} onConvert={onConvert} />
        </Modal>
      )}
    </Flex>
  );
}

export default memo(EditorActions);
