import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthUser, Project, ProjectTag, ResponseData } from '../../types';
import { updatePassword, updateProfile, User } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { uniqBy } from 'lodash';
import { updateUser } from '../../utils/dbActions';

interface AuthData {
  userData: ResponseData | null;
  allTags: ProjectTag[];
  updateAuthData: boolean;
  authMessage: { type: 'success' | 'error'; text: string } | null;
  timeout: boolean;
}

const initialState: AuthData = {
  userData: { user: null, allFolders: [], allProjects: [] },
  allTags: [],
  updateAuthData: false,
  authMessage: null,
  timeout: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetData: state => {
      state.userData = null;
      localStorage.clear();
      state.timeout = false;
    },
    setData: (state, action) => {
      state.userData = action.payload;
      // NEW USER
      if (!action.payload?.user) {
        let { uid, email, displayName } = { ...auth.currentUser } as User;
        const newUser = { id: uid, email: email || '', displayName: displayName || '', allFolders: [], allProjects: [] };
        console.log({ newUser });
        state.userData = { ...action.payload, user: newUser };
        updateUser({
          user: newUser,
        });
      }
    },
    setAllTags: (state, action) => {
      // get all unique project tags
      const _allTags = action.payload?.allProjects?.reduce((all: ProjectTag[], p: Project) => {
        if (p?.tags) {
          const tags = p.tags.map(t => {
            return { value: t.value, label: t.label };
          });
          const joined = [...all, ...tags];
          const unique = uniqBy(joined, 'value');
          return [...unique];
        }
        return all;
      }, []);

      state.allTags = _allTags;
    },
    setSessionTimeout: (state, action) => {
      state.timeout = action.payload;
    },
  },
  extraReducers: builder => {
    // add cases for pending and error
    builder

      .addCase(onSaveUser.rejected, state => {
        state.authMessage = { type: 'error', text: 'Could not save user profile' };
      })
      .addCase(onResetPassword.fulfilled, state => {
        state.updateAuthData = true;
        state.authMessage = { type: 'success', text: 'Succesfully reset password' };
      })
      .addCase(onResetPassword.rejected, state => {
        state.authMessage = { type: 'error', text: 'Could not reset password' };
      })
      .addCase(onSaveUser.fulfilled, state => {
        state.updateAuthData = true;
        state.authMessage = { type: 'success', text: 'Saved user profile' };
      });
  },
});

// SAVE USER
export const onSaveUser = createAsyncThunk('auth/onSaveUser', async (user: AuthUser | null) => {
  if (auth.currentUser && user) {
    const response = updateProfile(auth.currentUser, {
      displayName: user.displayName,
    }).then(() => {
      const saveUser = async () => {
        try {
          await setDoc(doc(db, 'users'), user);

          return Promise.resolve();
        } catch (e) {
          return Promise.reject();
        }
      };
      return saveUser();
    });
    return response;
  }
  return Promise.reject();
});
// RESET PASSWORD
export const onResetPassword = createAsyncThunk('auth/onResetPassword', async (newPassword: string) => {
  if (auth.currentUser) {
    return updatePassword(auth.currentUser, newPassword)
      .then(() => {
        return Promise.resolve();
      })
      .catch(error => {
        console.error(error);
        return Promise.reject();
      });
  }
  return Promise.reject();
});

export const { resetData, setData, setAllTags, setSessionTimeout } = authSlice.actions;
export default authSlice.reducer;
