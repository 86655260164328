import { Input } from 'antd';
import GlobalModal, { GlobalModalProps } from './GlobalModal';
import { Folder, InputChangeEvent } from '../../types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { onUpdateFolder } from '../../state/project/services';

export default function RenameFolder({ folder }: { folder: Folder }) {
  const user = useSelector((state: RootState) => state.auth.userData?.user);
  const dispatch = useDispatch<AppDispatch>();

  const [renamedFolder, setFolder] = useState(folder);

  // FOLDER NAME CHANGE
  const onFolderViewNameChange = (e: InputChangeEvent) => {
    e.preventDefault();
    setFolder({ ...renamedFolder, name: e.target.value });
  };

  // SAVE FOLDER NAME
  function saveFolderName() {
    const updated: Folder = { ...renamedFolder };
    updated.name = renamedFolder.name;
    dispatch(onUpdateFolder({ folder: updated, user, type: 'save' }));
  }

  const modal: GlobalModalProps = {
    title: 'Rename Folder',
    content: <Input value={renamedFolder.name} onChange={onFolderViewNameChange} />,
    okText: 'Save',
    onOk: saveFolderName,
  };
  return <GlobalModal {...modal} disableOk={!folder.name} />;
}
