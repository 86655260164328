import { Check } from '@phosphor-icons/react';
import { Button, Flex } from 'antd';
import { RowItem } from '../../../../types';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../../state/store';
import { useDispatch, useSelector } from 'react-redux';
import { onDoneToggle } from '../../../../state/project/project';

export default function FinishNextRow() {
  const project = useSelector((state: RootState) => state.project.project);
  const dispatch = useDispatch<AppDispatch>();

  const { editMode, activeTab } = useProjectContext();
  const [disabled, setDisabled] = useState(false);

  const finishNextRow = () => {
    if (!project) return;
    const currRows: RowItem[] = [...project.pattern];

    let i = currRows.length - 1;
    while (i >= 0) {
      const curr = currRows[i];
      const before = currRows[i - 1];
      if (!curr?.done && before?.done) {
        break;
      }
      i = i - 1;
    }

    if (i > 0) {
      dispatch(onDoneToggle({ i, status: true }));
    }
  };
  useEffect(() => {
    if (!project?.pattern) return;
    const lastRow = project.pattern[project.pattern.length - 1];
    setDisabled(lastRow?.done || false);
  }, [project?.pattern]);

  if (!project) return <></>;

  const showBtn = project?.pattern?.length > 0 && activeTab === 'pattern' && !editMode;

  return (
    <Flex justify="end" className="finish-next-row">
      {showBtn && (
        <Button onClick={finishNextRow} disabled={disabled} size="small" type="primary" icon={<Check size={14} weight="bold" />}>
          Finish next row
        </Button>
      )}
    </Flex>
  );
}
