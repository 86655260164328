import { theme } from 'antd';
import { headerHeight } from './constants';

const token = {
  colorPrimary: '#479ece',
  // '#171c5d',
  colorSecondary: '#1f628e',
};
const components = {
  Menu: {
    itemBg: 'transparent',
    itemPaddingInline: '1px',
    itemSelectedColor: '#fff',
    itemSelectedBg: '#ffffff47',
    itemColor: '#fff',
    itemHoverColor: '#fff',
  },
  Breadcrumb: {
    separatorColor: `${token.colorSecondary}c7`,
    linkColor: `${token.colorSecondary}c7`,
    lastItemColor: token.colorSecondary,
    linkHoverColor: token.colorSecondary,
  },
  Layout: {
    siderBg: '#fff',
    bodyBg: '#f8fafd',
    headerHeight: headerHeight,
    headerBg: '#f8fafd',
  },

  Tabs: {
    verticalItemPadding: '.5em 1em',
    // itemSelectedColor: '#7178bc',
    itemColor: '#999',
    cardGutter: 5,
  },
  Select: {
    optionSelectedBg: '#eaf7ff',
    optionFontSize: 12,
    optionHeight: 28,
  },
};
const config = {
  algorithm: theme.defaultAlgorithm,
  components,
  token,
};

export default config;
