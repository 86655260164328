import { theme } from 'antd';
import { ReactNode } from 'react';
const { useToken } = theme;

export default function CustomDropdown({ children }: { children: ReactNode }) {
  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  // const menuStyle: React.CSSProperties = {
  //   boxShadow: 'none',
  // };
  return (
    <div style={contentStyle} className="custom-dropdown">
      {children}
    </div>
  );
}
