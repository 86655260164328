import { doc, setDoc, writeBatch } from 'firebase/firestore';
import { AuthUser, Folder, Project } from '../types';
import { db } from '../firebase';

type ActionProps = {
  user: AuthUser | null | undefined;
  onError?: () => void;
};

interface AddFolderProps extends ActionProps {
  newFolder: Folder;
}
interface AddProjectProps extends ActionProps {
  newProject: Project;
}
interface UpdateProjectProps extends ActionProps {
  project: Project;
}
interface UpdateFolderProps extends ActionProps {
  folder: Folder;
}
interface RemoveProps extends ActionProps {
  id: string;
  itemType: 'folder' | 'project';
}

export const updateUser = async ({ user }: ActionProps): Promise<void> => {
  try {
    if (user?.id) {
      await setDoc(doc(db, 'users', user.id), user);
    }
  } catch (e) {
    console.error(e);
  }
};

export const addFolder = async ({ user, newFolder }: AddFolderProps): Promise<void> => {
  if (user) {
    const updated: AuthUser = { ...user } as AuthUser;
    updated.allFolders.push(newFolder);
    updateUser({ user: updated });
  }
};
export const addProject = async ({ user, newProject }: AddProjectProps): Promise<void> => {
  if (user) {
    const updated: AuthUser = { ...user } as AuthUser;
    updated.allProjects.push(newProject);
    updateUser({ user: updated });
  }
};
export const updateProject = async ({ user, project }: UpdateProjectProps): Promise<void> => {
  if (user) {
    const updated: AuthUser = { ...user } as AuthUser;
    updated.allProjects = updated.allProjects.map((p: Project) => {
      if (p.id === project.id) {
        return { ...project, lastModified: `${new Date()}` };
      }
      return p;
    });
    updateUser({ user: updated });
  }
};
export const updateFolder = async ({ user, folder }: UpdateFolderProps): Promise<void> => {
  if (user) {
    const updated: AuthUser = { ...user } as AuthUser;
    updated.allFolders = updated.allFolders.map((f: Folder) => {
      if (f.id === folder.id) {
        return { ...folder, lastModified: `${new Date()}` };
      } else {
        return f;
      }
    });
    updateUser({ user: updated });
  }
};

export const removeItem = async ({ user, id, itemType }: RemoveProps): Promise<void> => {
  if (user) {
    const updated: AuthUser = { ...user } as AuthUser;
    if (itemType === 'project') {
      updated.allProjects = updated.allProjects.filter((p: Project) => p.id !== id);
    } else if (itemType === 'folder') {
      updated.allFolders = updated.allFolders.filter((f: Folder) => f.id !== id);
    }
    updateUser({ user: updated });
  }
};

export const deleteFolderWithProjects = async ({ folderId, projectIds }: { folderId: string; projectIds: string[] }): Promise<void> => {
  console.log({ folderId, projectIds });

  try {
    const batch = writeBatch(db);
    batch.delete(doc(db, 'folders', folderId));
    projectIds.forEach((id: string) => {
      batch.delete(doc(db, 'projects', id));
    });

    await batch.commit();
  } catch (e) {
    console.log(e);
    // if (onError) onError();
  }
};
