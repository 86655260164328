import { Button, Dropdown, Popconfirm } from 'antd';
import CustomDropdown from '../../global/CustomDropdown';
import { ArrowClockwise, DotsThreeOutlineVertical, Pencil } from '@phosphor-icons/react';
import usePageLocation from '../../../utils/hooks/usePageLocation';
import { useProjectContext } from '../../../context/ProjectContext';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../state/store';
import { onCheckboxReset, onHandleTimer } from '../../../state/project/project';

export default function ProjectMenu({ toggleEditMode }: { toggleEditMode: () => void }) {
  const { onAdd } = usePageLocation();
  const { editMode, activeTab } = useProjectContext();
  const { project } = useSelector((state: RootState) => state.project);

  const dispatch = useDispatch<AppDispatch>();
  const hasTimer = project.timer;

  // CONFIRM CB RESET
  const confirmCheckboxReset = () => {
    dispatch(onCheckboxReset());
  };

  const handleTimer = () => {
    dispatch(onHandleTimer(!hasTimer));
  };

  return !onAdd && !editMode ? (
    <Dropdown
      dropdownRender={() => {
        return (
          <CustomDropdown>
            <Button onClick={() => toggleEditMode()} icon={<Pencil size={18} weight="fill" />} type="text">
              Edit
            </Button>

            {/* {project.timer ? (
              <Popconfirm title="Remove Timer" description="Are you sure you want to remove your timer?" onConfirm={handleTimer} okText="Yes" cancelText="No" placement="bottomLeft">
                <Button danger type="text" icon={<TimerIcon style={{ fontSize: '1.2em' }} />}>
                  Remove Timer
                </Button>
              </Popconfirm>
            ) : (
              <Button onClick={() => handleTimer()} icon={<TimerIcon style={{ fontSize: '1.2em' }} />} type="text">
                Add Timer
              </Button>
            )} */}
            {!onAdd && !editMode && activeTab === 'pattern' && (
              <Popconfirm title="Reset Checkmarks" description="Are you sure you want to reset?" onConfirm={confirmCheckboxReset} okText="Yes" cancelText="No" placement="bottomLeft">
                <Button danger type="text" icon={<ArrowClockwise size={18} weight="bold" />}>
                  Reset Checkmarks
                </Button>
              </Popconfirm>
            )}
          </CustomDropdown>
        );
      }}
    >
      <Button icon={<DotsThreeOutlineVertical size={18} weight="fill" />} shape="circle" type="text" className="project-menu" />
    </Dropdown>
  ) : (
    <></>
  );
}
