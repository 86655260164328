import { Dispatch, SetStateAction, useMemo } from 'react';
import { ProjectHeader } from '../../../types';
import { Button, Flex, Layout } from 'antd';
import { pjctNavWidth, pjctNavWidthSmall } from '../../../utils/constants';
import { TextIndent, TextOutdent } from '@phosphor-icons/react';
import { HeadersType } from './PatternTab';
const { Sider } = Layout;

type PatternNavType = {
  headers: HeadersType;
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
};

export default function ProjectNav({ headers, collapsed, setCollapsed }: PatternNavType) {
  const goToHeader = (h: ProjectHeader) => {
    const foundRef = headers.refs[h.refId];
    if (foundRef) {
      foundRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const triggerIcon = collapsed ? <TextIndent size={20} weight="bold" /> : <TextOutdent size={20} weight="bold" />;

  const siderStyle: React.CSSProperties = useMemo(() => {
    return {
      overflow: 'auto',
      height: '100vh',
      insetInlineStart: 0,
      scrollbarWidth: 'thin',
      scrollbarColor: 'unset',
      background: 'transparent',
      // left: siderWidth + 30,
      marginRight: `-${collapsed ? pjctNavWidthSmall : pjctNavWidth}px`,
    };
  }, [collapsed]);
  return (
    <Sider collapsible collapsed={collapsed} trigger={null} style={{ ...siderStyle }} className="project-nav" width={pjctNavWidth} collapsedWidth={pjctNavWidthSmall}>
      <Flex justify="end">
        <Button onClick={() => setCollapsed(!collapsed)} icon={triggerIcon} type="text" />
      </Flex>

      {!collapsed && (
        <div className="headers">
          {headers.all.map(h => {
            return (
              <Button onClick={() => goToHeader(h)} key={h.id} type="text">
                {h.value}
              </Button>
            );
          })}
        </div>
      )}
    </Sider>
  );
}
