import { Button, Dropdown, Radio, RadioChangeEvent, Space } from 'antd';
import CustomDropdown from '../../../global/CustomDropdown';
import { FadersHorizontal } from '@phosphor-icons/react';
import { RowItem, RowType } from '../../../../types';
import { useState } from 'react';
import { rowTypes } from '../../../../utils/constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../state/store';
import { onRowUpdate } from '../../../../state/project/project';
import { useProjectContext } from '../../../../context/ProjectContext';

type RowEditorProps = {
  index: number;
  row: RowItem;
};
export default function RowEditorMenu({ index, row }: RowEditorProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { activeTab } = useProjectContext();

  const [rowType, setRowType] = useState<RowType>(row.type);
  const [openModal, setOpenModal] = useState(false);

  const handleRowChange = (e: RadioChangeEvent) => {
    const rowType = e.target.value as RowType;
    setRowType(rowType);
    dispatch(onRowUpdate({ index, field: 'type', fieldVal: '', activeTab, rowType }));
  };

  const handleFormatSave = (formatted: string) => {
    dispatch(onRowUpdate({ index, field: 'value', fieldVal: formatted, activeTab, rowType }));
    toggleModal();
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const changeableRows = rowTypes.filter(r => r !== 'image');

  return row.type !== 'image' ? (
    <Dropdown
      dropdownRender={() => {
        return (
          <CustomDropdown>
            <div className="add-action-menu">
              <Space direction="vertical">
                <Radio.Group onChange={handleRowChange} value={rowType}>
                  <Space direction="vertical">
                    {changeableRows.map((t: RowType, i: number) => {
                      return (
                        <Radio value={t} key={i}>
                          {t}
                        </Radio>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </Space>
            </div>
          </CustomDropdown>
        );
      }}
    >
      <Button icon={<FadersHorizontal size={18} weight="fill" />} type="text" shape="circle" />
    </Dropdown>
  ) : null;
}
