import { RowType } from '../types';

const headerHeight = 45;
const breadcrumbHeight = 30;
const tabNavHeight = 35;
const projectHeaderHeight = 65;
const editActionsHeight = 50;
const totalHeight = breadcrumbHeight + projectHeaderHeight + tabNavHeight + 20;

const sideNavWidth = 170;
const pjctNavWidth = 140;
const pjctNavWidthSmall = 35;

const rowTypes: RowType[] = ['row', 'note', 'header', 'image'];

export { totalHeight, headerHeight, breadcrumbHeight, projectHeaderHeight, tabNavHeight, pjctNavWidth, pjctNavWidthSmall, rowTypes, editActionsHeight, sideNavWidth };
