import { Button, Flex, Modal, Select } from 'antd';
import { useState } from 'react';
import { Project, ResponseData, RowItem } from '../../../../types';
import '../../../../styles/CopyAbbreviations.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../state/store';
import { onCopyAbbreviations } from '../../../../state/project/project';

export default function CopyAbbreviations() {
  const userData = useSelector((state: RootState) => state.auth.userData);
  const dispatch = useDispatch<AppDispatch>();
  const { allProjects } = userData as ResponseData;

  const projectsWithAbbrs = allProjects.filter((p: Project) => p.abbreviations.length > 0);

  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<Project | null>(projectsWithAbbrs?.[0] || null);
  const [currAbrs, setCurrAbrs] = useState<RowItem[]>(projectsWithAbbrs?.[0]?.abbreviations || '');

  const projectOptions = projectsWithAbbrs.map((p: Project) => {
    return { value: p.id, label: p.name };
  });
  const cancelHandler = () => {
    setOpenModal(false);
  };
  const onOkHandler = () => {
    dispatch(onCopyAbbreviations(currAbrs));
  };

  const handleSelect = (id: string) => {
    const selectedProj = projectsWithAbbrs.find((p: Project) => p.id === id);
    setSelected(selectedProj || null);
    setCurrAbrs(selectedProj?.abbreviations || []);
  };

  const footer = [
    <Button onClick={onOkHandler} type="primary" key="1">
      Copy from {selected?.name}
    </Button>,
    <Button onClick={cancelHandler} key="2">
      Cancel
    </Button>,
  ];

  return (
    <>
      <Flex justify="center" style={{ marginTop: '2em' }}>
        <Button onClick={() => setOpenModal(true)}>Copy abbreviations from other project</Button>
      </Flex>
      <Modal title="Choose project to copy from:" open={openModal} onCancel={cancelHandler} footer={footer} closeIcon={null} width={700} style={{ maxHeight: '300px' }}>
        {selected && (
          <div className="abbrs-modal-container">
            <Select value={selected.id} onChange={handleSelect} options={projectOptions} size="small" className="sort-select" />

            <div className="abbrs-container">
              {currAbrs.map((a: RowItem) => {
                return (
                  <Flex className="abbr" key={a.id}>
                    <span>{a.label}</span> <span>{a.value}</span>
                  </Flex>
                );
              })}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
