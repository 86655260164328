import { PlusCircle, Tag as TagIcon } from '@phosphor-icons/react';
import { Button, Divider, Flex, Input, InputRef, Select, Tag } from 'antd';
import { useRef, useState } from 'react';
import getUniqueId from '../../../utils/getUniqueId';
import { useProjectContext } from '../../../context/ProjectContext';
import { ProjectTag } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../state/store';
import { onAddTag } from '../../../state/project/project';

export default function ProjectTags() {
  const inputRef = useRef<InputRef>(null);
  const { editMode } = useProjectContext();
  const { allTags } = useSelector((state: RootState) => state.auth);
  const project = useSelector((state: RootState) => state.project.project);
  const dispatch = useDispatch<AppDispatch>();

  const _selectedValues = project?.tags?.filter(t => t.selected)?.map(t => t.value);
  const selectedLabels = project?.tags?.filter(t => t.selected)?.map(t => t.label);

  const [tag, setTag] = useState('');
  const [tagOptions, setTagOptions] = useState<ProjectTag[]>(allTags);
  const [selectedValues, setSelectedValues] = useState<string[]>(_selectedValues || []);

  if (!project) return <>no project</>;

  const onTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTag(event.target.value);
  };

  const addTag = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    const newTag = { value: getUniqueId(), label: tag };
    setTagOptions([...tagOptions, newTag]);
    setTag('');
    let currTags = [];
    if (!Array.isArray(project?.tags)) {
      currTags = [newTag];
    } else {
      currTags = [...project.tags, newTag];
    }
    dispatch(onAddTag(currTags));

    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onSelect = (selectedValues: string[]) => {
    const filtered = selectedValues.filter((s: string) => tagOptions.map(t => t.value).includes(s));
    const currTags = tagOptions.map(t => {
      return { ...t, selected: filtered.includes(t.value) };
    });

    setSelectedValues(filtered);
    dispatch(onAddTag(currTags));
  };

  return (
    <div className="project-tags">
      <p>Project Tags</p>
      {editMode && (
        <Select
          onChange={onSelect}
          mode="tags"
          placeholder="select tags"
          value={selectedValues}
          // filterOption={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
          dropdownRender={menu => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Flex style={{ padding: '.2em 1em .4em' }} align="center">
                <Input placeholder="Add new tag" ref={inputRef} value={tag} onChange={onTagChange} onKeyDown={e => e.stopPropagation()} style={{ width: '150px', marginRight: '.5em' }} size="small" />
                <Button type="text" icon={<PlusCircle fill="bold" size={24} />} onClick={addTag} shape="circle" size="small" />
              </Flex>
            </>
          )}
          options={tagOptions}
        />
      )}
      {!editMode && (
        <Flex>
          {selectedLabels?.length ? (
            selectedLabels?.map((t, i) => {
              return (
                <Tag key={i} className="tag-item" icon={<TagIcon size={12} weight="bold" />}>
                  {t}
                </Tag>
              );
            })
          ) : (
            <Tag icon={<TagIcon size={12} weight="bold" />} className="tag-item">
              No tags
            </Tag>
          )}
        </Flex>
      )}
      <Divider style={{ margin: '1em 0 .5em' }} />
    </div>
  );
}
