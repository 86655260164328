import { Envelope, Pencil, UserCircle } from '@phosphor-icons/react';
import { Flex, Input, Tabs, Typography } from 'antd';
import { useState } from 'react';
import GlobalModal, { GlobalModalProps } from './GlobalModal';
import '../../styles/UserProfile.scss';
import { AuthUser, InputChangeEvent } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { onResetPassword, onSaveUser } from '../../state/auth/auth';
const { Text } = Typography;

export default function EditUser({ closeModal }: { closeModal: () => void }) {
  const user = useSelector((state: RootState) => state.auth.userData?.user);
  const dispatch = useDispatch<AppDispatch>();

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [currUser, setCurrUser] = useState<AuthUser | null>(user || null);
  const [newPassword, setNewPassword] = useState({ password: '', confirmed: '' });
  const [activeTab, setActiveTab] = useState('user');
  const [pwMatches, setPWMatches] = useState(false);

  const saveUser = () => {
    dispatch(onSaveUser(currUser));
  };

  const resetPassword = () => {
    dispatch(onResetPassword(newPassword.password));
  };

  const handleUserChange = (e: InputChangeEvent) => {
    if (currUser) {
      setCurrUser({ ...currUser, [e.target.name]: e.target.value });
      setUnsavedChanges(true);
    }
  };
  const handlePWChange = (e: InputChangeEvent) => {
    const updated = { ...newPassword, [e.target.name]: e.target.value.trim() };
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value.trim() });
    console.log({ updated });

    if (updated.password.length > 7 && updated.password === updated.confirmed) {
      setPWMatches(true);
    } else {
      setPWMatches(false);
    }
  };

  const userContent = (
    <div className="user-profile-editor">
      <Input value={`${currUser?.displayName}`} onChange={handleUserChange} prefix={<UserCircle size={16} />} name="displayName" placeholder="Name" />
      <Input value={`${currUser?.email}`} onChange={handleUserChange} prefix={<Envelope size={16} />} name="email" placeholder="Email" disabled />
    </div>
  );
  const resetPasswordTab = (
    <div className="pw-editor">
      <Input.Password value={newPassword.password} onChange={handlePWChange} name="password" placeholder="New Password" />
      <Text className="pw-hint" disabled>
        Password must be at least 8 characters
      </Text>
      <Input.Password value={newPassword.confirmed} onChange={handlePWChange} name="confirmed" placeholder="Confirm Password" style={{ marginTop: '1em' }} />
    </div>
  );
  const tabs = (
    <Tabs
      // defaultActiveKey="password"
      onChange={tab => setActiveTab(tab)}
      items={[
        {
          label: 'User',
          key: 'user',
          children: userContent,
        },
        {
          label: 'Reset Password',
          key: 'password',
          children: resetPasswordTab,
        },
      ]}
    />
  );

  const modal: GlobalModalProps = {
    title: (
      <Flex align="center" className="edit-user-title">
        <Pencil size={28} weight="fill" style={{ marginRight: '.5em' }} />
        <h3>Edit Profile </h3>
      </Flex>
    ),
    onOk: () => {
      activeTab === 'user' ? saveUser() : resetPassword();
    },
    okText: activeTab === 'user' ? 'Save' : 'Reset Password',
    content: tabs,
  };
  return <GlobalModal {...modal} closeModal={closeModal} disableOk={activeTab === 'user' ? !unsavedChanges : !pwMatches} />;
}
