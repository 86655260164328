import { LegacyRef } from 'react';
import { RowItem, InputChangeEvent } from '../../types';
import { InputRef } from 'antd';

type GetInputProps = {
  type: 'label' | 'value';
  row: RowItem;
  i: number;
  focusRef?: LegacyRef<InputRef>;
  handleRowChange: (e: InputChangeEvent, i: number, field: 'label' | 'value' | 'width') => void;
  handleFocus: (i: number) => void;
};

// INPUT PROPS
export function getInputProps({ type, row, i, handleRowChange, handleFocus }: GetInputProps) {
  let props: any = {
    name: `row-${type}-${i}`,
    value: row[type],
    onChange: (e: InputChangeEvent) => handleRowChange(e, i, type),
    onFocus: (i: number) => handleFocus(i),
    size: row.type === 'header' ? 'large' : 'default',
    style: row.type === 'header' ? { fontWeight: 'bold' } : null,
  };

  return props;
}
