import { Pencil, SignOut, UserCircle } from '@phosphor-icons/react';
import { Button, Dropdown, MenuProps } from 'antd';
import '../styles/UserProfile.scss';
import { useState } from 'react';
import { EditUserModal, SignoutModal } from './modals';
import { useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { useProjectContext } from '../context/ProjectContext';
import CustomDropdown from './global/CustomDropdown';

export default function UserProfile() {
  const [modalType, setModalType] = useState('');
  const user = useSelector((state: RootState) => state.auth.userData?.user);
  const { isMobile } = useProjectContext();

  const items: MenuProps['items'] = [];
  items.push({
    key: 'editUser',
    label: (
      <Button onClick={() => setModalType('editUser')} icon={<Pencil size={16} weight="bold" />} type="primary" size="small" className="user-menu-item">
        Edit profile
      </Button>
    ),
  });
  items.push({
    key: 'signOut',
    label: (
      <Button onClick={() => setModalType('signOut')} icon={<SignOut size={16} weight="fill" />} size="small" className="user-menu-item signout">
        Sign Out
      </Button>
    ),
  });

  const closeModal = () => {
    setModalType('');
  };

  return (
    <>
      <Dropdown
        placement="topRight"
        dropdownRender={() => {
          return (
            <div className="user-dropdown">
              <CustomDropdown>
                {isMobile && (
                  <div className="name-email">
                    <div>{user?.displayName}</div>
                    <div>{user?.email}</div>
                  </div>
                )}
                {items.map((item: any) => {
                  return <div key={item.key}>{item.label}</div>;
                })}
              </CustomDropdown>
            </div>
          );
        }}
      >
        <Button icon={<UserCircle size={28} weight="fill" />} type="text" className="user-profile-btn">
          {!isMobile && (
            <div className="name-email">
              <div>{user?.displayName}</div>
              <div>{user?.email}</div>
            </div>
          )}
        </Button>
      </Dropdown>
      {modalType === 'editUser' && <EditUserModal closeModal={closeModal} />}
      {modalType === 'signOut' && <SignoutModal closeModal={closeModal} />}
    </>
  );
}
