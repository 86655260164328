import { Folder, Project, ProjectTag, SortBy } from '../types';

const sortItems = ({ items, sortBy }: { items: Project[] | Folder[]; sortBy: SortBy }): Project[] | Folder[] => {
  let sorted = [...items];
  if (sorted.length) {
    sorted = sorted.sort((a: Project | Folder, b: Project | Folder) => {
      let result = 0;
      switch (sortBy.type) {
        case 'lastModified':
          const aDate = new Date(a.lastModified).getTime();
          const bDate = new Date(b.lastModified).getTime();
          result = sortBy.desc ? bDate - aDate : aDate - bDate;
          break;
        case 'name':
          result = sortBy.desc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
          break;
      }

      return result;
    });
  }
  return sorted;
};

const filterProjects = ({ projects, tagFilters }: { projects: Project[]; tagFilters: string[] }) => {
  const filtered = projects.filter((p: Project) => {
    const found = p?.tags?.find((t: ProjectTag) => tagFilters.includes(t.value) && t.selected);
    return found;
  });
  return filtered;
};

export { sortItems, filterProjects };
