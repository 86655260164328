import { configureStore } from '@reduxjs/toolkit';
import projectReducer from './project/project';
import authReducer from './auth/auth';
import { userDataApi } from './auth/userDataApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    project: projectReducer,
    [userDataApi.reducerPath]: userDataApi.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(userDataApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
