export default function ErrorBoundary() {
  // const [hasError, setHasError] = useState();

  return <>hey</>;
}
// class ErrorBoundary extends React.Component {
//   constructor(props: {children: ReactNode}) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   componentDidCatch(error:any, info:any) {
//     // Example "componentStack":
//     //   in ComponentThatThrows (created by App)
//     //   in ErrorBoundary (created by App)
//     //   in div (created by App)
//     //   in App
//     // logErrorToMyService(error, info.componentStack);
//     console.log({error, info});

//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return<></>;
//     }

//     return this.props.children;
//   }
// }
