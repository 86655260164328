import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// import admin from 'firebase-admin';

// const config = {
//   credential: admin.credential.cert({
//     // Your JSON config file goes here
//   }),
// };

const firebaseConfig = {
  apiKey: 'AIzaSyDpgECQwMRepr2RrC-Lgs8X_HyXxkVRXYw',
  authDomain: 'tmdhcily.firebaseapp.com',
  projectId: 'tmdhcily',
  storageBucket: 'tmdhcily.appspot.com',
  messagingSenderId: '66279792214',
  appId: '1:66279792214:web:65455bd4b604f6b25665f7',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

const db = getFirestore();
export { db, auth, storage, app };

// import { initializeApp } from 'https://www.gstatic.com/firebasejs/9.0.0/firebase-app.js';
// import { getAuth } from 'https://www.gstatic.com/firebasejs/9.0.0/firebase-auth.js';
// import { getFirestore } from 'https://www.gstatic.com/firebasejs/9.0.0/firebase-firestore.js';

// import { initializeApp as initializeAdminApp } from 'firebase-admin/app';
// import { getAuth as getAdminAuth } from 'firebase-admin/auth';

// const adminApp = initializeAdminApp();
// const adminAuth = getAdminAuth();
