import { Button, Modal } from 'antd';
import { ReactNode, useState } from 'react';

export type GlobalModalProps = {
  title: string | ReactNode;
  // setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  closeModal?: () => void;
  onCancel?: () => void;
  onOk?: () => void;
  okText?: string;
  cancelText?: string;
  content?: ReactNode;
  disableOk?: boolean;
  maskClosable?: boolean;
  danger?: boolean;
  closable?: boolean;
};

export default function GlobalModal({ title, onOk, okText, onCancel, cancelText, content, closeModal, disableOk, maskClosable, danger, closable = true }: GlobalModalProps) {
  const [openModal, setOpenModal] = useState(true);
  const cancelHandler = () => {
    if (onCancel) {
      onCancel();
    }
    closeHandler();
  };
  const closeHandler = () => {
    if (closeModal) {
      closeModal();
    } else {
      setOpenModal(false);
    }
  };
  const onOkHandler = () => {
    if (onOk) onOk();
    closeHandler();
  };

  const footer = [];
  if (onOk) {
    footer.push(
      <Button onClick={onOkHandler} type="primary" key="1" disabled={disableOk} danger={danger}>
        {okText || 'Ok'}
      </Button>,
    );
  }
  footer.push(
    <Button onClick={cancelHandler} key="2">
      {cancelText || 'Cancel'}
    </Button>,
  );

  return (
    <Modal title={title} open={openModal} onCancel={cancelHandler} closeIcon={null} footer={footer} maskClosable={maskClosable || true} closable={closable}>
      {content}
    </Modal>
  );
}
