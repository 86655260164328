import { useState } from 'react';
import getUniqueId from '../../utils/getUniqueId';
import { Folder, InputChangeEvent } from '../../types';
import { Input } from 'antd';
import GlobalModal, { GlobalModalProps } from './GlobalModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { onUpdateFolder } from '../../state/project/services';

export default function NewFolder({ closeModal }: { closeModal: () => void }) {
  const user = useSelector((state: RootState) => state.auth.userData?.user);
  const dispatch = useDispatch<AppDispatch>();

  const [newFolderName, setNewFolderName] = useState('');
  const [folderError, setFolderError] = useState(false);
  // CREATE FOLDER
  function createFolder() {
    if (!newFolderName) {
      setFolderError(true);
      return;
    }
    setFolderError(false);
    const id = getUniqueId();
    const newFolder: Folder = { name: newFolderName, id, lastModified: `${new Date()}` };
    dispatch(onUpdateFolder({ folder: newFolder, user, type: 'add' }));
  }
  const modal: GlobalModalProps = {
    title: 'New Folder Name',
    onOk: createFolder,
    okText: 'Create Folder',
    content: (
      <Input
        onChange={(e: InputChangeEvent) => {
          setNewFolderName(e.target.value);
          setFolderError(false);
        }}
        status={folderError ? 'error' : ''}
        name="folderName"
        style={{ margin: '.7em 0 2em' }}
        placeholder="Folder Name"
      />
    ),
  };
  return <GlobalModal {...modal} closeModal={closeModal} />;
}
