import { FolderSimple, House } from '@phosphor-icons/react';
import { Breadcrumb, Flex } from 'antd';
import '../../styles/Global.scss';
import { useParams } from 'react-router-dom';
import { useProjectContext } from '../../context/ProjectContext';
import usePageLocation from '../../utils/hooks/usePageLocation';

type BreadcrumbMenu = {
  title: JSX.Element;
  href?: string;
};

export default function Breadcrumbs({ folderName, projectName }: { folderName?: string; projectName?: string }) {
  let { folderId } = useParams();
  const { isMobile } = useProjectContext();
  const { onHome, onAdd } = usePageLocation();

  const onFolderView = folderName && !projectName;
  const onFolderProjectView = folderName && projectName;

  const folderTitle = (
    <>
      <FolderSimple size={14} weight="fill" />
      <span>{folderName}</span>
    </>
  );
  const items: BreadcrumbMenu[] = [
    {
      href: '/my-projects',
      title: <House size={14} weight="fill" />,
    },
  ];

  if (onFolderView) {
    // home / FOLDER

    if (onAdd) {
      items.push({
        href: `/my-projects/folder/${folderId}`,
        title: folderTitle,
      });
      items.push({
        title: <span>Add Project</span>,
      });
    } else {
      items.push({
        title: folderTitle,
      });
    }
  } else if (onFolderProjectView) {
    // home / FOLDER / PROJECT
    items.push({
      href: `/my-projects/folder/${folderId}`,
      title: folderTitle,
    });
    items.push({
      title: <span>{projectName}</span>,
    });
  } else if (projectName) {
    // home / PROJECT
    items.push({
      title: <span>{projectName}</span>,
    });
  } else if (onAdd) {
    items.push({
      title: <span>Add Project</span>,
    });
  }

  return !isMobile || (!onHome && isMobile) ? (
    <Flex justify="space-between" align="center" className="breadcrumbs">
      <Breadcrumb className="bread-crumbs" items={items} />
    </Flex>
  ) : (
    <></>
  );
}
