import { useLocation, useParams } from 'react-router-dom';

export default function usePageLocation() {
  let { projectId } = useParams();
  const location = useLocation();

  const onAdd = location.pathname.includes('add');
  const onHome = location.pathname.replaceAll('/', '') === 'my-projects';
  const onFolder = location.pathname.includes('folder');
  const onProject = !!projectId;

  return { onAdd, onHome, onFolder, onProject };
}
