import { useNavigate } from 'react-router-dom';
import GlobalModal, { GlobalModalProps } from './GlobalModal';

export default function UnsavedChanges({ closeModal }: { closeModal: () => void }) {
  const navigate = useNavigate();
  //TODO;
  // const { saveProject } = useProjectContext();

  const modal: GlobalModalProps = {
    title: 'You have unsaved changes',
    onCancel: () => navigate('/my-projects'),
    onOk: () => {
      //TODO;
      // saveProject();
      navigate('/my-projects');
    },
    okText: 'Save Project',
    cancelText: 'Discard Changes',
  };

  return <GlobalModal {...modal} closeModal={closeModal} />;
}
