import { Button, Flex, Input, message, Typography } from 'antd';
import '../styles/Login.scss';
import { Envelope, Password, User } from '@phosphor-icons/react';
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useCallback, useState } from 'react';
import { InputChangeEvent } from '../types';
import { auth } from '../firebase';
import { getFormattedDate } from '../utils/getFormattedDate';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../state/store';
import { userDataApi } from '../state/auth/userDataApi';
const { Text } = Typography;

export default function Login() {
  const [messageApi, msgContextHolder] = message.useMessage();
  // const [login, setLogin] = useState({ email: 'ljnacht@gmail.com', password: 'grampacat12345' });
  const [login, setLogin] = useState({ email: '', password: '' });
  const [newAccount, setNewAccount] = useState({ displayName: '', email: '', password: '' });
  const [loginError, setLoginError] = useState(false);
  const [viewMode, setViewMode] = useState('login');
  const [disableCreate, setDisableCreate] = useState(true);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const fetchData = useCallback(() => {
    dispatch(userDataApi.endpoints.getUserData.initiate({ subscribe: false, forceRefetch: true })).then(() => {
      dispatch(userDataApi.util.resetApiState());
    });
  }, [dispatch]);

  const onSubmitLogin = () => {
    setLoading(true);
    signInWithEmailAndPassword(auth, login.email, login.password)
      .then(credentials => {
        localStorage.setItem('login', getFormattedDate());
        credentials.user.getIdToken().then(token => {
          localStorage.setItem('authToken', token);
        });
        localStorage.setItem('uid', `${credentials.user.uid}`);
        dispatch(userDataApi.endpoints.getUserData.initiate({ subscribe: false, forceRefetch: true })).then(() => {
          dispatch(userDataApi.util.resetApiState());
        });
        // Get the user's ID token as it is needed to exchange for a session cookie.
        // return user.getIdToken().then(idToken => {
        //   // Session login endpoint is queried and the session cookie is set.
        //   // CSRF protection should be taken into account.
        //   // ...
        //   const csrfToken = getCookie('csrfToken')
        //   return postIdTokenToSessionLogin('/sessionLogin', idToken, csrfToken);
        // });
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setLoginError(true);
        console.error(errorCode, errorMessage);
      });
  };

  const onLoginChange = (e: InputChangeEvent) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
    setLoginError(false);
  };

  const onNewAccountChange = (e: InputChangeEvent) => {
    const updated = { ...newAccount, [e.target.name]: e.target.value };
    setNewAccount(updated);
    const { displayName, email, password } = updated;
    if (displayName.length && email.length && password.length > 7) {
      setDisableCreate(false);
    }
  };

  const onCreateUser = () => {
    setLoading(true);
    const { displayName, email, password } = newAccount;
    if (displayName && email && password) {
      createUserWithEmailAndPassword(auth, email, password)
        .then(userCredential => {
          // Signed up
          const user = userCredential.user;
          updateProfile(user, {
            displayName,
          });
          fetchData();
        })
        .catch(error => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage);
          messageApi?.open({
            type: 'error',
            content: `${errorMessage}`,
          });
        });
    }
  };

  const onResetPassword = () => {
    if (login.email) {
      sendPasswordResetEmail(auth, login.email)
        .then(() => {
          setTimeout(() => {
            messageApi?.open({
              type: 'success',
              content: 'An email has been sent to reset your password',
            });
          }, 100);
          setViewMode('login');
        })
        .catch(error => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage);
          messageApi?.open({
            type: 'error',
            content: `${errorMessage}`,
          });
          // ..
        });
    }
  };

  return (
    <Flex className="login-container" justify="center" align="center" vertical>
      {viewMode === 'login' && (
        // LOGIN
        <>
          <Flex align="center" justify="center" className="logo">
            <img src="/assets/yarn-ball.png" alt="logo" />
            <div>
              <p className="yarn">yarn</p>
              <p className="werk">WERK</p>
            </div>
          </Flex>

          <Flex justify="center" vertical className="login-content">
            <Input value={login.email} onChange={onLoginChange} name="email" status={loginError ? 'error' : ''} prefix={<Envelope size={20} weight="bold" />} placeholder="Email" />
            <Input.Password value={login.password} onChange={onLoginChange} name="password" status={loginError ? 'error' : ''} prefix={<Password size={20} weight="bold" />} placeholder="Password" />

            <Button onClick={onSubmitLogin} type="text" className="action-btn" loading={loading && !loginError}>
              Log In
            </Button>

            <div className="login-actions">
              <Button onClick={() => setViewMode('reset')} type="text" className="user-action-btn">
                Forgot password?
              </Button>
              {' | '}

              <Button onClick={() => setViewMode('create')} type="text" className="user-action-btn">
                Create new user
              </Button>
            </div>
          </Flex>
        </>
      )}
      {viewMode === 'create' && (
        // CREATE USER
        <Flex align="center" vertical className="login-content">
          <h1>Create an account</h1>
          <Input value={newAccount.displayName} onChange={onNewAccountChange} name="displayName" prefix={<User size={20} weight="bold" />} placeholder="Full Name" />
          <Input value={newAccount.email} onChange={onNewAccountChange} name="email" prefix={<Envelope size={20} weight="bold" />} placeholder="Email" />
          <Input.Password
            value={newAccount.password}
            onChange={onNewAccountChange}
            name="password"
            // status={loginError ? 'error' : ''}
            prefix={<Password size={20} weight="bold" />}
            placeholder="Password"
            style={{ marginBottom: 0 }}
          />
          <Text className="pw-hint">Password must be at least 8 characters</Text>
          <Button onClick={onCreateUser} type="text" className="action-btn" disabled={disableCreate} loading={loading}>
            Create account
          </Button>
          <div className="login-actions">
            <Button onClick={() => setViewMode('login')} type="text" className="user-action-btn">
              Back to login
            </Button>
          </div>
        </Flex>
      )}

      {viewMode === 'reset' && (
        // FORGOT PASSWORD
        <Flex align="center" vertical className="login-content">
          <h1>Reset Password</h1>
          <Input value={login.email} onChange={onLoginChange} name="email" status={loginError ? 'error' : ''} prefix={<Envelope size={20} weight="bold" />} placeholder="Email" />

          <Button onClick={onResetPassword} type="text" className="action-btn" disabled={newAccount.email.length > 0}>
            Reset
          </Button>
          <div className="login-actions">
            <Button onClick={() => setViewMode('login')} type="text" className="user-action-btn">
              Back to login
            </Button>
          </div>
        </Flex>
      )}
      {msgContextHolder}
    </Flex>
  );
}
