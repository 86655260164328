import { useNavigate, useParams } from 'react-router-dom';
import '../styles/ProjectView.scss';
import { Project } from '../types';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { FilePlus, FloppyDiskBack } from '@phosphor-icons/react';
import { Button, Flex, Input, Select } from 'antd';
import { PatternTab } from '../components/project/index';
import { useProjectContext } from '../context/ProjectContext';
import { projectHeaderHeight } from '../utils/constants';
import usePageLocation from '../utils/hooks/usePageLocation';
import { FinishNextRow, ProjectMenu } from '../components/project/components';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../state/store';
import { onUpdateProject } from '../state/project/services';
import { onDoneToggle, onNameChange } from '../state/project/project';

export default function ProjectView() {
  const navigate = useNavigate();

  let { folderId } = useParams();
  const { onAdd } = usePageLocation();

  const user = useSelector((state: RootState) => state.auth.userData?.user);
  const { project, automaticSave, unsavedChanges } = useSelector((state: RootState) => state.project);
  const dispatch = useDispatch<AppDispatch>();

  const { editMode, toggleEditMode, activeTab, setActiveTab } = useProjectContext();

  const bottomRef = useRef() as RefObject<HTMLDivElement>;
  const [currTimer, setCurrTimer] = useState(parseInt(`${localStorage.getItem('timer')}`) || 0);

  // SAVE
  const saveProject = useCallback(
    (isDoneToggleSave?: boolean) => {
      if (unsavedChanges || isDoneToggleSave) {
        if (!isDoneToggleSave) {
          toggleEditMode();
        }
        let currProject = { ...project };
        if (project.timer) {
          const localTimer = parseInt(`${localStorage.getItem('timer')}`) || 0;
          currProject = { ...project, timer: localTimer };
        }

        dispatch(onUpdateProject({ project: currProject, user, type: 'save' }));
      } else if (!isDoneToggleSave) {
        toggleEditMode();
      }
    },
    [project, dispatch, user, unsavedChanges, toggleEditMode],
  );

  // TIMER FOR AUTO SAVING
  useEffect(() => {
    const interval = setInterval(() => {
      const localTimer = parseInt(`${localStorage.getItem('timer')}`) || 0;
      const timerChanged = localTimer !== currTimer;

      if (automaticSave > 0 || timerChanged) {
        saveProject(true);
        setCurrTimer(localTimer);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [automaticSave, saveProject]);

  if (!project) return <>no project</>;
  // ADD PROJECT
  function addProject() {
    const newProject: Project = folderId ? { ...project, folder: folderId, lastModified: `${new Date()}` } : project;
    dispatch(onUpdateProject({ project: newProject, user, type: 'add' })).then(() => {
      navigate(`/my-projects${folderId ? `/folder/${folderId}` : ''}`);
    });
  }

  // DONE TOGGLE
  const toggleDone = (i: number) => {
    dispatch(onDoneToggle({ i }));
  };

  const tabOptions = [
    {
      value: 'pattern',
      label: 'Pattern',
    },
    {
      value: 'specs',
      label: 'Project Info',
    },
    {
      value: 'abbreviations',
      label: 'Abbreviations',
    },
  ];

  return (
    <div className="project-view">
      {/* PROJECT HEADER */}
      <Flex className="project-header" justify="space-between" align="center" style={{ height: `${projectHeaderHeight}px` }}>
        {/* EDIT / RESET */}
        <ProjectMenu toggleEditMode={toggleEditMode} />

        {/* SAVE  */}
        {!onAdd && editMode && <Button shape="circle" type={'primary'} onClick={() => saveProject()} icon={<FloppyDiskBack size={20} weight="fill" />} />}
        {/* ADD  */}
        {onAdd && <Button onClick={() => addProject()} disabled={!project.name} type="primary" icon={<FilePlus size={18} weight="fill" />} shape="circle" />}

        {/* NAME */}
        {onAdd || editMode ? (
          <Input name="projectName" value={project.name} onChange={e => dispatch(onNameChange(e.target.value))} placeholder="Project Name" className="project-name-input" />
        ) : (
          <h1>{project.name}</h1>
        )}
      </Flex>

      {/* TIMER */}
      {/* <Timer /> */}

      {/* FINISH LAST ROW */}
      <FinishNextRow />
      <div className="tab-selector">
        <Select value={activeTab} onChange={tab => setActiveTab(tab)} options={tabOptions} className="tab-input" />
      </div>

      {/* PATTERN TAB CONTENT */}

      {activeTab === 'pattern' ? <PatternTab toggleDone={toggleDone} /> : <PatternTab />}

      <div ref={bottomRef}></div>
    </div>
  );
}
