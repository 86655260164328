import { Flex } from 'antd';
import { Folder, Project, ResponseData } from '../../types';
import { Folder as FolderIcon } from '@phosphor-icons/react';
import GlobalModal from './GlobalModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { onUpdateProject } from '../../state/project/services';
import { useState } from 'react';

export default function MoveToFolder({ project }: { project: Project }) {
  const userData = useSelector((state: RootState) => state.auth.userData);
  const dispatch = useDispatch<AppDispatch>();
  const { allFolders } = userData as ResponseData;
  const [openModal, setOpenModal] = useState(true);
  // const { onFolder } = usePageLocation();

  // SAVE SELECTED FOLDER
  function selectFolder(folderId: string) {
    type NewType = Project;

    const updated: Project = { ...project } as NewType;
    updated.folder = folderId;
    // const thenActions = () => {
    //   setSuccess(`Project ${onFolder ? 'removed from folder' : 'moved to folder'}`);
    // };
    // const onError = () => setError(`Could not save project`);
    dispatch(onUpdateProject({ project: updated, user: userData?.user, type: 'save' })).then(() => {
      setOpenModal(false);
    });
  }

  const modal = {
    title: 'Select Folder',
    content: allFolders.map((folder: Folder) => {
      return (
        <div onClick={() => selectFolder(folder.id)} className="thumbnail flat" key={folder.id}>
          <Flex className="icon-name">
            <FolderIcon weight="fill" size={20} />
            <span>{folder.name}</span>
          </Flex>
        </div>
      );
    }),
  };
  return openModal ? <GlobalModal {...modal} /> : null;
}
