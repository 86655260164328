import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project, ProjectTag, RowItem, RowType, TabType } from '../../types';
import getUniqueId from '../../utils/getUniqueId';
import { onDeleteItem, onUpdateFolder, onUpdateProject } from './services';
import { UniqueIdentifier } from '@dnd-kit/core';

const initialProject: Project = {
  name: '',
  id: getUniqueId(),
  pattern: [],
  specs: [],
  abbreviations: [],
  lastModified: '',
};
type ProjectSliceType = {
  project: Project;
  unsavedChanges: boolean;
  automaticSave: number;
  updateData: boolean;
  message: { type: 'success' | 'error'; text: string } | null;
  loading: boolean;
  timer: number;
};

const initialState: ProjectSliceType = {
  project: initialProject,
  unsavedChanges: false,
  automaticSave: 0,
  updateData: false,
  message: null,
  loading: true,
  timer: 0,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setProjectStore: (state, action: PayloadAction<{ id?: string; allProjects: Project[] }>) => {
      const { id, allProjects } = action.payload;

      let currProject = { ...initialProject, id: `${Date.now()}` };
      if (id) {
        const found = allProjects?.find((p: Project) => p.id === id) as Project;
        if (found) currProject = found;
      }
      state.project = { ...currProject };
      state.loading = false;
    },
    onCopyAbbreviations: (state, action: PayloadAction<RowItem[]>) => {
      state.project.abbreviations = action.payload;
      state.unsavedChanges = true;
    },
    onAddTag: (state, action: PayloadAction<ProjectTag[]>) => {
      state.project.tags = action.payload;
      state.unsavedChanges = true;
    },
    onAddNewRow: (state, action: PayloadAction<{ row: RowItem | RowItem[]; activeTab: TabType; multiple?: boolean }>) => {
      const { activeTab, row, multiple } = action.payload;
      let added: RowItem[] = [...state.project[activeTab]];
      if (multiple && Array.isArray(row)) {
        added = [...added, ...row];
      } else if (!Array.isArray(row)) {
        added = [...state.project[activeTab], row];
      }
      state.project[activeTab] = added;
      state.unsavedChanges = true;
    },
    onAddRowAbove: (state, action: PayloadAction<{ row: RowItem; activeTab: TabType; indexBelow: number }>) => {
      const { activeTab, row, indexBelow } = action.payload;
      let added: RowItem[] = [...state.project[activeTab]];
      added.splice(indexBelow, 0, row);
      state.project[activeTab] = added;
      state.unsavedChanges = true;
    },
    onDeleteRow: (state, action: PayloadAction<{ id: UniqueIdentifier; activeTab: TabType }>) => {
      const { activeTab, id } = action.payload;

      const rows = JSON.parse(JSON.stringify([...state.project[activeTab]]));
      const deleted = rows.filter((_r: RowItem) => _r.id !== id);
      console.log({ rows, deleted, id });

      state.project[activeTab] = deleted;
      state.unsavedChanges = true;
    },
    onRowUpdate: (state, action: PayloadAction<{ index: number; activeTab: TabType; fieldVal: string; field: 'value' | 'label' | 'width' | 'type'; rowType?: RowType }>) => {
      const { activeTab, index, field, fieldVal, rowType } = action.payload;
      // TODO: refactor this logic to switch case probably
      if (state.project[activeTab][index]) {
        if (field === 'width') {
          const width = parseInt(fieldVal);
          state.project[activeTab][index] = { ...state.project[activeTab][index], width };
        } else if (field === 'type' && rowType) {
          state.project[activeTab][index].type = rowType;
        } else if (field !== 'type') {
          state.project[activeTab][index][field] = fieldVal;
        }
        state.unsavedChanges = true;
      }
    },
    onCheckboxReset: state => {
      const currRows = [...state.project.pattern];
      const resetRows: RowItem[] = currRows.map((row: RowItem) => {
        return { ...row, done: false };
      });
      state.project.pattern = resetRows;
      state.automaticSave = state.automaticSave + 1;
    },
    onNameChange: (state, action: PayloadAction<string>) => {
      state.project.name = action.payload;
      state.unsavedChanges = true;
    },
    onDoneToggle: (state, action: PayloadAction<{ i: number; status?: boolean }>) => {
      const { i, status } = action.payload;

      const currRows: RowItem[] = [...state.project.pattern];
      console.log({ currRows });

      currRows[i].done = Boolean(status) ? status : !currRows[i].done;

      state.project.pattern = currRows;
      state.automaticSave = state.automaticSave + 1;
    },
    onHandleTimer: (state, action: PayloadAction<boolean>) => {
      const add = action.payload;
      if (add) {
        state.project.timer = 1;
      } else {
        state.project.timer = 0;
      }
      state.automaticSave = state.automaticSave + 1;
    },
    setTimer: (state, action) => {
      state.timer = action.payload;

      state.automaticSave = state.automaticSave + 1;
    },
    setUnsaved: (state, action: PayloadAction<{ type: 'changes' | 'toggles'; status: boolean }>) => {
      const { type, status } = action.payload;
      if (type === 'changes') {
        state.unsavedChanges = status;
      } else if (type === 'toggles') {
        state.automaticSave = status ? state.automaticSave + 1 : 0;
      }
    },
    resetUpdateData: state => {
      state.updateData = false;
      state.message = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(onUpdateProject.fulfilled, (state, fulfilledMeta) => {
        const { project } = fulfilledMeta.meta.arg;
        const projName = project?.name || 'project';
        state.unsavedChanges = false;
        state.updateData = true;
        if (state.automaticSave > 0) {
          state.automaticSave = 0;
        } else {
          state.message = { type: 'success', text: `Saved ${projName}` };
        }
      })
      .addCase(onUpdateFolder.fulfilled, (state, fulfilledMeta) => {
        state.updateData = true;
        state.message = { type: 'success', text: 'Saved folder' };
      })
      .addCase(onDeleteItem.fulfilled, state => {
        state.updateData = true;
        state.message = { type: 'success', text: 'Deleted project/folder' };
      })
      .addCase(onUpdateProject.rejected, state => {
        state.message = { type: 'error', text: 'Could not save project' };
        state.automaticSave = 0;
      })
      .addCase(onUpdateFolder.rejected, state => {
        state.message = { type: 'error', text: 'Could not save folder' };
      })
      .addCase(onDeleteItem.rejected, state => {
        state.message = { type: 'error', text: 'Could not delete project/folder' };
      });
    // TODO: on pending
  },
});

export default projectSlice.reducer;

export const {
  onCopyAbbreviations,
  onDoneToggle,
  setProjectStore,
  onAddTag,
  onAddNewRow,
  onAddRowAbove,
  onDeleteRow,
  onRowUpdate,
  onCheckboxReset,
  onNameChange,
  setUnsaved,
  resetUpdateData,
  onHandleTimer,
  setLoading,
  setTimer,
} = projectSlice.actions;
