import { EditorProvider, JSONContent, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { RowItem, RowType } from '../../../../types';
import getUniqueId from '../../../../utils/getUniqueId';
import { Button, Flex } from 'antd';
import { useState } from 'react';
import { CheckSquare, TextAlignJustify, TextHOne } from '@phosphor-icons/react';

type LineType = {
  text: string;
  type: string;
  rowType: RowType;
};
type PatternCompilerProps = {
  closeModal: () => void;
  onConvert: (r: RowItem[]) => void;
};

const Footer = ({ closeModal, onConvert }: PatternCompilerProps) => {
  const { editor } = useCurrentEditor();

  const [formatted, setFormatted] = useState(false);
  const [lines, setLines] = useState<LineType[]>([]);

  if (!editor) {
    return null;
  }
  const onFormat = () => {
    const json = editor.getJSON();

    const allowedTypes = ['heading', 'codeBlock', 'blockquote'];

    let currLine = '';
    console.log(json.content);

    const allLines: LineType[] =
      json.content?.reduce((all: LineType[], value: JSONContent, i: number) => {
        let rowType = 'note' as RowType;
        let type = value.type || '';
        let text = value.content?.[0]?.text;
        if (value.content?.length && value.content?.length > 1) {
          text = value.content.reduce((concat: string, t) => `${concat}${t.text}`, '');
          console.log({ text });
        }
        if (allowedTypes.includes(type)) {
          if (type === 'heading') {
            rowType = 'header';
          } else if (type === 'codeBlock') {
            rowType = 'row';
          } else if (type === 'blockquote') {
            rowType = 'note';
            text = value.content
              ?.map(n => {
                return `${n?.content?.[0]?.text}`;
              })
              .join('\n');
          }
          if (currLine) {
            all.push({ text: currLine, type: 'blockquote', rowType: 'note' });
            currLine = '';
          }
          all.push({ text: text || '', type, rowType });
        } else {
          currLine = `${currLine}${currLine ? '\n' : ''}${text}`;
        }
        return all;
      }, []) || [];

    if (currLine) {
      allLines.push({ text: currLine, type: 'blockquote', rowType: 'note' });
    }

    const formattedLines = allLines.map(line => {
      let content: any = [{ type: 'text', text: line.text }];
      let attrs = {};
      if (line.type === 'heading') {
        attrs = { level: 2 };
      } else if (line.rowType === 'note') {
        const notes = line.text.split('\n');
        content = notes.map(n => {
          return { type: 'paragraph', content: [{ type: 'text', text: n }] };
        });
      }

      return {
        type: line.type,
        content,
        attrs: { ...attrs },
      };
    });

    editor.commands.setContent({
      type: 'doc',
      content: formattedLines,
    });

    setLines(allLines);
    setFormatted(true);
  };
  const convert = () => {
    const rows = lines.reduce((all: RowItem[], line: LineType, i: number) => {
      const { text } = line;
      if (text.length > 0) {
        const isRow = line.rowType === 'row';
        let label = isRow ? 'Row ' : '';
        let value = text;

        if (isRow) {
          const rowArr = text.split(':');
          if (rowArr.length > 1) {
            label = rowArr[0];
            value = rowArr[1];
          }
        } else if (line.rowType === 'note') {
          const noteArr = text.split('\n');
          const t = noteArr.map(s => `<p>${s}</p>`).join('');
          value = t;
        }

        const toAdd: RowItem = {
          label,
          value,
          done: false,
          type: line.rowType,
          id: `${getUniqueId()}${i}`,
        };
        all.push(toAdd);
      }
      return all;
    }, []);

    onConvert(rows);
    closeModal();
  };

  editor.on('update', () => {
    setFormatted(false);
  });

  return (
    <Flex justify="end">
      <Button onClick={closeModal} style={{ marginRight: '1em' }}>
        Cancel
      </Button>
      <Button onClick={onFormat} type="primary" style={{ marginRight: '1em' }} disabled={formatted || editor.getText().length < 1}>
        Check formatting
      </Button>
      <Button onClick={convert} type="primary" disabled={!formatted}>
        Convert Pattern
      </Button>
    </Flex>
  );
};

const Formatter = () => {
  const { editor } = useCurrentEditor();
  if (!editor) return null;

  const format = (formatFunc: () => void) => {
    editor.chain().focus().clearNodes().run();
    formatFunc();
  };

  return (
    <Flex className="formatter-btns" justify="space-between" align="center">
      <div>
        <Button
          onClick={() => format(() => editor.chain().focus().toggleHeading({ level: 2 }).run())}
          size="small"
          type={editor.isActive('heading', { level: 2 }) ? 'primary' : 'default'}
          icon={<TextHOne size={18} weight="bold" />}
        >
          Main Header
        </Button>

        <Button
          onClick={() => format(() => editor.chain().focus().toggleCodeBlock().run())}
          size="small"
          type={editor.isActive('codeBlock') ? 'primary' : 'default'}
          icon={<CheckSquare size={18} weight="bold" />}
        >
          Row
        </Button>

        <Button
          onClick={() => format(() => editor.chain().focus().toggleBlockquote().run())}
          size="small"
          type={editor.isActive('blockquote') ? 'primary' : 'default'}
          icon={<TextAlignJustify size={18} weight="bold" />}
        >
          Note
        </Button>
      </div>
      <div>
        <Button onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().chain().focus().undo().run()} size="small">
          Undo
        </Button>
        <Button onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().chain().focus().redo().run()} size="small">
          Redo
        </Button>
      </div>
    </Flex>
  );
};

const extensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
];

const content = '';
export default function PatternCompiler({ closeModal, onConvert }: PatternCompilerProps) {
  return (
    <div className="text-editor">
      <EditorProvider slotAfter={<Footer closeModal={closeModal} onConvert={onConvert} />} slotBefore={<Formatter />} extensions={extensions} content={content}></EditorProvider>
    </div>
  );
}
