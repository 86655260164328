import { Button, Flex, Select } from 'antd';
import { Dispatch, memo, SetStateAction } from 'react';
import { ArrowDown, ArrowUp } from '@phosphor-icons/react';
import { Tag as TagIcon } from '@phosphor-icons/react';
import { SortBy } from '../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';

type SortAndFilterProps = {
  setSortBy: Dispatch<SetStateAction<SortBy>>;
  sortBy: SortBy;
  setTagFilters: Dispatch<SetStateAction<string[]>>;
};

function SortAndFilter({ sortBy, setSortBy, setTagFilters }: SortAndFilterProps) {
  const { allTags } = useSelector((state: RootState) => state.auth);

  const sortOptions = [
    {
      value: 'lastModified',
      label: 'Last Modified',
    },
    {
      value: 'name',
      label: 'Name',
    },
  ];

  const handleTagSelect = (values: string[]) => {
    setTagFilters(values);
  };

  return (
    <Flex justify="start" align="center">
      <Button
        onClick={() => {
          setSortBy({ ...sortBy, desc: !sortBy.desc });
        }}
        icon={sortBy.desc ? <ArrowDown size={16} weight="bold" /> : <ArrowUp size={16} weight="bold" />}
        type="text"
        shape="circle"
      />

      <Select
        value={sortBy.type}
        onChange={type => {
          setSortBy({ ...sortBy, type });
        }}
        options={sortOptions}
        size="small"
        className="sort-select"
      />

      <TagIcon size={16} weight="fill" />
      <Select onChange={handleTagSelect} mode="tags" placeholder="Filter by project tags" size="small" options={allTags} className="filter-select" allowClear />
    </Flex>
  );
}

export default memo(SortAndFilter);
