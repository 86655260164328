import { ProjectHeader, RowItem } from '../../../types';
import { EditorView, ProjectNav } from '../index';
import RowsDisplay from './RowsDisplay';
import { useProjectContext } from '../../../context/ProjectContext';
import usePageLocation from '../../../utils/hooks/usePageLocation';
import { createRef, RefObject, useEffect, useState } from 'react';
import { RootState } from '../../../state/store';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';

type PatternTabProps = {
  toggleDone?: (i: number) => void;
};

export type HeadersType = {
  all: ProjectHeader[];
  refs: RefObject<HTMLHeadingElement>[];
};

export default function PatternTab({ toggleDone }: PatternTabProps) {
  const project = useSelector((state: RootState) => state.project.project);
  const { editMode, activeTab, isMobile } = useProjectContext();
  const { onAdd } = usePageLocation();
  const [headers, setHeaders] = useState<HeadersType>({ all: [], refs: [] });
  const [collapsed, setCollapsed] = useState(isMobile);

  useEffect(() => {
    const all = project
      ? project[activeTab]
          .filter((r: RowItem) => r.type === 'header')
          .map((r: RowItem, i: number) => {
            return { value: r.value, refId: i, id: r.id };
          })
      : [];
    const refs = all.map(() => createRef<HTMLHeadingElement>() || null);
    setHeaders({ refs, all });
  }, [project]);

  return (
    <Layout>
      {!editMode && headers.all.length > 0 && <ProjectNav headers={headers} collapsed={collapsed} setCollapsed={setCollapsed} />}

      <div className={`tab-container ${editMode ? 'edit-mode' : ''}`}>{onAdd || editMode ? <EditorView /> : <RowsDisplay toggleDone={toggleDone} headers={headers} collapsed={collapsed} />}</div>
    </Layout>
  );
}
