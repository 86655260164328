import '../styles/Home.scss';

export default function Home() {
  return (
    <div className="home">
      <div className="polaroids">
        <img className="evancrying1" src="/assets/evancrying1.jpeg" alt="" />
        <img className="evancrying2" src="/assets/crying2.png" alt="" />
      </div>

      <div className="thanks">
        <h1>Thanks for coming!!</h1>
      </div>
    </div>
  );
}
