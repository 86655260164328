import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import GlobalModal, { GlobalModalProps } from './GlobalModal';

export default function SessionTimeout() {
  const { onSignOut } = useAuthContext();
  const navigate = useNavigate();

  const modal: GlobalModalProps = {
    title: 'Your session has timed out',
    cancelText: 'Return to login',
    onCancel: () => {
      onSignOut();
      navigate('/my-projects');
    },
    maskClosable: false,
    closable: false,
  };
  return <GlobalModal {...modal} />;
}
