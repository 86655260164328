import { Button, Flex } from 'antd';
import { useCallback } from 'react';
import { RowItem, RowType } from '../../../../types';
import getUniqueId from '../../../../utils/getUniqueId';
import { useProjectContext } from '../../../../context/ProjectContext';
import { addActions, addIcon, addRowName } from '../EditorActions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../state/store';
import { onAddRowAbove } from '../../../../state/project/project';

export default function AddRowAbove({ indexBelow }: { indexBelow: number }) {
  const { activeTab } = useProjectContext();
  const dispatch = useDispatch<AppDispatch>();

  const addRowAbove = useCallback((type: RowType = 'row') => {
    const toAdd: RowItem = {
      label: activeTab === 'pattern' ? 'Row ' : '',
      value: '',
      done: false,
      type,
      id: getUniqueId(),
    };
    dispatch(onAddRowAbove({ row: toAdd, activeTab, indexBelow }));
  }, []);

  const addBtns = addActions.map((action: RowType) => {
    const isRow = action === 'row';
    return (
      <Button key={action} onClick={() => addRowAbove(action)} icon={addIcon} type="primary" size="small">
        {isRow ? addRowName(activeTab) : action}
      </Button>
    );
  });

  return (
    <Flex className="add-row-above" align="center">
      <div className="line"></div>
      <Flex className="actions" justify="center">
        {addBtns.map((item: any, i: number) => {
          return (
            <div key={i} className="add-action-btn">
              {item}
            </div>
          );
        })}
      </Flex>
      <div className="line"></div>
    </Flex>
  );
}
