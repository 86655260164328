import { ConfigProvider, Flex, Layout, Spin } from 'antd';
import themeConfig from '../utils/themeConfig';
import { ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Folder, Project } from '../types';
import usePageLocation from '../utils/hooks/usePageLocation';
import useDocumentTitle from '../utils/hooks/useDocumentTitle';
import { useGetUserDataQuery } from '../state/auth/userDataApi';
import { useSelector } from 'react-redux';
import { RootState } from '../state/store';
import Login from './Login';
import Header from '../components/Header';
import { Content } from 'antd/es/layout/layout';
import Breadcrumbs from '../components/global/Breadcrumbs';
import { SessionTimeoutModal } from '../components/modals';
import { useProjectContext } from '../context/ProjectContext';

export default function PageTemplate({ children }: { children: ReactNode }) {
  const { userData, timeout } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.project);
  const { isLoading } = useGetUserDataQuery({});
  const { onHome, onProject } = usePageLocation();
  const { siderWidth, isMobile } = useProjectContext();
  let { folderId, projectId } = useParams();
  const navigate = useNavigate();

  let allProjects: Project[] = userData?.allProjects || [];
  let allFolders: Folder[] = userData?.allFolders || [];
  const project = allProjects.find((p: Project) => p.id === projectId);
  const folder = allFolders.find((f: Folder) => f.id === folderId);

  useDocumentTitle({ project, folder });

  // if (!onHome && (!userData?.user || !localStorage.getItem('authToken'))) {
  //   navigate('/my-projects');
  // }

  return (
    <ConfigProvider theme={themeConfig}>
      {isLoading && !localStorage.getItem('authToken') && (
        <Flex className="loading-container" justify="center" align="center">
          <Spin size="large" />
        </Flex>
      )}
      {!userData?.user || !localStorage.getItem('authToken') ? (
        <Login />
      ) : (
        <Layout style={{ height: '100vh', marginTop: '' }} hasSider={!isMobile}>
          <Header />
          <Layout style={{ marginInlineStart: siderWidth }}>
            {!onHome && <Breadcrumbs projectName={project?.name} folderName={folder?.name} />}
            {((!loading && onProject) || !onProject) && <Content>{children}</Content>}
          </Layout>
          {timeout && <SessionTimeoutModal />}
        </Layout>
      )}
    </ConfigProvider>
  );
}
