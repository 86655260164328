export const getFormattedDate = () => {
  const currentTime = new Date();
  const formattedCurrentTime = currentTime.toLocaleString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  });
  return formattedCurrentTime;
};
