import { useNavigate, useParams } from 'react-router-dom';
import { FolderSimplePlus, FilePlus, SquaresFour, List } from '@phosphor-icons/react';
import { MenuProps, Button, Layout, Flex, Divider, Dropdown } from 'antd';
import '../styles/Header.scss';
import { memo, useState } from 'react';
import { NewFolderModal, UnsavedChangesModal } from './modals';
import UserProfile from './UserProfile';
import usePageLocation from '../utils/hooks/usePageLocation';
import { AppDispatch, RootState } from '../state/store';
import { useDispatch, useSelector } from 'react-redux';
import { onUpdateProject } from '../state/project/services';
import { useProjectContext } from '../context/ProjectContext';
import CustomDropdown from './global/CustomDropdown';

const { Sider, Header } = Layout;

function SiteHeader() {
  const navigate = useNavigate();
  const { onAdd } = usePageLocation();
  const { projectId, folderId } = useParams();
  const { isTimerOn, setIsTimerOn, siderWidth, isMobile } = useProjectContext();

  const { unsavedChanges, automaticSave, project } = useSelector((state: RootState) => state.project);
  const dispatch = useDispatch<AppDispatch>();

  const { userData } = useSelector((state: RootState) => state.auth);

  const [modalType, setModalType] = useState('');

  // NAV HOME
  const navigateHome = () => {
    if (unsavedChanges) {
      setModalType('unsavedChanges');
    } else if (automaticSave || isTimerOn) {
      setIsTimerOn(false);
      dispatch(onUpdateProject({ project, user: userData?.user, type: 'save' })).then(() => {
        navigate('/my-projects');
      });
    } else {
      navigate('/my-projects');
    }
  };
  const closeModal = () => {
    setModalType('');
  };

  const menuItems: MenuProps['items'] = [];

  // ADD PROJECT
  const addProject = {
    key: 'project',
    label: 'Add Project',
    icon: <FilePlus size={18} weight="fill" />,
    onClick: () => navigate('add', { relative: 'path' }),
  };
  // ADD FOLDER
  const addFolder = {
    key: 'folder',
    label: 'Add Folder',
    icon: <FolderSimplePlus size={18} weight="fill" />,
    onClick: () => setModalType('newFolder'),
  };
  // MY PROJECTS
  const myProjects = {
    key: 'home',
    label: 'My Projects',
    icon: <SquaresFour size={22} weight="fill" />,
    onClick: navigateHome,
  };

  if (projectId || onAdd) {
    menuItems.push(myProjects);
  } else {
    menuItems.push(addProject);
    if (!folderId) {
      menuItems.push(addFolder);
    }
  }

  const Logo = (
    <button className="logo" onClick={navigateHome}>
      <Flex align="center" justify={isMobile ? '' : 'center'}>
        <img src="/assets/yarn-ball.png" alt="logo" />
        <div>
          <p className="yarn">yarn</p>
          <p className="werk">WERK</p>
        </div>
      </Flex>
    </button>
  );

  return (
    <>
      {isMobile ? (
        <Header className="header">
          {Logo}
          <UserProfile />
          <Dropdown
            dropdownRender={() => {
              return (
                <CustomDropdown>
                  {menuItems.map((item: any, i: number) => {
                    if (!item) return <></>;

                    return (
                      <div key={item.key} className="menu-item">
                        <Button onClick={item.onClick} icon={item.icon} type="text">
                          {item.label}
                        </Button>
                        {i < menuItems.length - 1 && <Divider />}
                      </div>
                    );
                  })}
                </CustomDropdown>
              );
            }}
          >
            <Button icon={<List size={24} />} shape="circle" type="text" />
          </Dropdown>
        </Header>
      ) : (
        <Sider style={siderStyle} className="sider" width={siderWidth}>
          {Logo}
          <Divider style={{ marginBottom: '1em' }} />
          <UserProfile />
          <Divider style={{ marginTop: '1em' }} />

          <div className="header-menu">
            {menuItems.map((item: any, i: number) => {
              if (!item) return <></>;

              return (
                <div key={item.key} className="menu-item">
                  <Button onClick={item.onClick} icon={item.icon} type="text">
                    {item.label}
                  </Button>
                  {i < menuItems.length - 1 && <Divider />}
                </div>
              );
            })}
          </div>
        </Sider>
      )}

      {/* MODALS */}
      {modalType === 'newFolder' && <NewFolderModal closeModal={closeModal} />}
      {modalType === 'unsavedChanges' && <UnsavedChangesModal closeModal={closeModal} />}
    </>
  );
}

export default memo(SiteHeader);

const siderStyle: React.CSSProperties = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
};

{
  /* <Header className="header" style={{ height: `${headerHeight}px` }}>
        {Logo}
        <Flex align="center">
          {isLoading && <Spin style={{ marginRight: '20px' }} />}
          <UserProfile />
          <Dropdown
            menu={{ items: menuItems }}
            dropdownRender={() => {
              return (
                <div style={menuStyle}>
                  {menuItems.map((item: any) => {
                    if (!item) return <></>;

                    return (
                      <div key={item.key} className="menu-item">
                        <Button onClick={item.onClick} icon={item.icon} type="text">
                          {item.label}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          >
            <Button type="text">{onAdd || onProject ? <List size={24} /> : <Plus size={24} />}</Button>
          </Dropdown>
        </Flex>
      </Header> */
}
