import { createContext, useCallback, useContext, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { message } from 'antd';
import { NoticeType } from 'antd/es/message/interface';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../state/store';
import { resetData } from '../state/auth/auth';
import { userDataApi } from '../state/auth/userDataApi';

type AuthContextType = {
  onSignOut: () => void;
};

const initialState: AuthContextType = {
  onSignOut: () => {},
};

const AuthContext = createContext<AuthContextType | undefined>(initialState);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { authMessage, updateAuthData } = useSelector((state: RootState) => state.auth);
  const { updateData } = useSelector((state: RootState) => state.project);
  const dispatch = useDispatch<AppDispatch>();

  const [messageApi, msgContextHolder] = message.useMessage();

  const fetchData = useCallback(() => {
    dispatch(userDataApi.endpoints.getUserData.initiate({ subscribe: false, forceRefetch: true })).then(() => {
      dispatch(userDataApi.util.resetApiState());
    });
  }, [dispatch]);

  // AUTH STATE LISTENER
  onAuthStateChanged(auth, user => {
    if (user) {
      user.getIdToken().then(token => {
        localStorage.setItem('authToken', token);
      });
      localStorage.setItem('uid', user.uid);
    } else {
      // User is signed out
      dispatch(resetData());
    }
  });

  // UPDATE DATA LISTENER
  useEffect(() => {
    if (updateData || updateAuthData) {
      fetchData();
    }
  }, [updateData, updateAuthData, fetchData]);

  // SIGNOUT
  const onSignOut = () => {
    signOut(auth);
  };

  // ALERT MESSAGE
  const onMessage = useCallback(
    (type: NoticeType, msg: string) => {
      messageApi?.open({
        type,
        content: msg,
      });
    },
    [messageApi],
  );

  // ON SUCCESS or ERROR
  useEffect(() => {
    if (authMessage) {
      onMessage(authMessage.type, `${authMessage.text}`);
    }
  }, [authMessage, onMessage]);

  const contextValue = { onSignOut };

  return (
    <AuthContext.Provider value={contextValue}>
      {children} {msgContextHolder}
      {/* {sessionTimedOut && <SessionTimeoutModal />} */}
    </AuthContext.Provider>
  );
};

export function useAuthContext(): AuthContextType {
  const context = useContext(AuthContext) as AuthContextType;
  if (context === undefined) {
    throw new Error('context is undefined');
  }
  return context;
}
