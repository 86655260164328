import usePageLocation from './usePageLocation';
import { Folder, Project } from '../../types';

export default function useDocumentTitle({ project, folder }: { project?: Project; folder?: Folder }) {
  //   const location = useLocation();
  const { onAdd, onFolder } = usePageLocation();
  let title = 'YarnWerk';
  if (onAdd) {
    title = `${title} | Add Project`;
  } else if (onFolder && folder && !project) {
    title = `${title} | ${folder.name}`;
  } else if (project) {
    title = `${title} | ${project.name}`;
  }

  document.title = title;
  //   return {};
}
