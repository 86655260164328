import { Flex } from 'antd';
import { Folder, Project, ResponseData } from '../../types';
import GlobalModal, { GlobalModalProps } from './GlobalModal';
import { Folder as FolderIcon, Yarn } from '@phosphor-icons/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { onDeleteItem } from '../../state/project/services';

export default function DeleteItem({ item, isFolder }: { item: Folder | Project; isFolder?: boolean }) {
  const userData = useSelector((state: RootState) => state.auth.userData);
  const dispatch = useDispatch<AppDispatch>();

  const { allProjects } = userData as ResponseData;
  let projectsInFolder: Project[] = [];
  if (isFolder) {
    projectsInFolder = allProjects.filter((p: Project) => p.folder === item.id);
  }

  // DELETE
  function deleteItem() {
    dispatch(onDeleteItem({ id: item.id, isFolder, userData }));
  }

  const modal: GlobalModalProps = {
    title: `Are you sure you want to delete this ${isFolder ? 'folder' : 'project'}?`,
    content: (
      <div className="thumbnail flat no-click">
        <Flex className="icon-name">
          {isFolder ? <FolderIcon weight="fill" size={20} /> : <Yarn weight="fill" size={20} />}
          <span>{item.name}</span>
        </Flex>
        {/* show projects inside folder to be deleted */}
        {projectsInFolder && (
          <div className="projects-in-folder">
            {projectsInFolder.map((p: Project) => {
              return (
                <Flex className="icon-name" key={p.id}>
                  <Yarn weight="fill" size={20} /> <span>{p.name}</span>
                </Flex>
              );
            })}
          </div>
        )}
      </div>
    ),
    onOk: () => deleteItem(),
    okText: 'Delete',
    danger: true,
  };
  return <GlobalModal {...modal} />;
}
