import { Flex, Input } from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import SortAndFilter from './SortAndFilter';
import { Dispatch, memo, SetStateAction } from 'react';
import { SortBy } from '../../types';
import '../../styles/Search.scss';

type SearchProps = {
  setSearch: Dispatch<SetStateAction<string>>;
  setSortBy: Dispatch<SetStateAction<SortBy>>;
  sortBy: SortBy;
  setTagFilters: Dispatch<SetStateAction<string[]>>;
};

function SearchProjects({ setSearch, setSortBy, sortBy, setTagFilters }: SearchProps) {
  const sortFilterProps = { setTagFilters, setSortBy, sortBy };

  return (
    <>
      {/* SEARCH */}
      <Flex align="center" justify="space-between" className="search-container">
        <Input onChange={e => setSearch(e.target.value.toLowerCase().trim())} prefix={<SearchIcon />} allowClear className="search-input" />
      </Flex>

      {/* SORT + FILTER */}
      <div className={`sort-and-filters`}>
        {/* className={`sort-and-filters ${showFilters ? 'open' : ''}`} */}
        <SortAndFilter {...sortFilterProps} />
      </div>
    </>
  );
}

export default memo(SearchProjects);
