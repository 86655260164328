import '../../../styles/Editor.scss';
import { Button, Checkbox, Input } from 'antd';
import { InputChangeEvent, RowItem, RowType } from '../../../types';
import { memo, RefObject, useCallback, useRef, useState } from 'react';
import { Trash } from '@phosphor-icons/react';
import { getInputProps } from '../utils';
import { useProjectContext } from '../../../context/ProjectContext';
import EditorActions, { EditorActionsProps } from './EditorActions';
import getUniqueId from '../../../utils/getUniqueId';
import ImageItem from './ImageItem';
import Empty from '../../global/Empty';
import { AddRowAbove, CopyAbbreviations, ProjectTags, RowEditorMenu, RowValueInput } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../state/store';
import { onAddNewRow, onDeleteRow, onRowUpdate } from '../../../state/project/project';
import { UniqueIdentifier } from '@dnd-kit/core';

// type EditorViewProps = {};

function EditorView() {
  const bottomRef = useRef() as RefObject<HTMLDivElement>;

  const project = useSelector((state: RootState) => state.project.project);
  const dispatch = useDispatch<AppDispatch>();
  const { activeTab } = useProjectContext();
  // const { onAdd } = usePageLocation();

  const [currFocus, setCurrFocus] = useState<number>(-1);
  const [deleteMode, setDeleteMode] = useState(false);

  // ADD ROW
  const addNewRow = useCallback(
    (type: RowType = 'row') => {
      const toAdd: RowItem = {
        label: activeTab === 'pattern' ? 'Row ' : '',
        value: '',
        done: false,
        type,
        id: getUniqueId(),
      };

      dispatch(onAddNewRow({ row: toAdd, activeTab }));

      // scroll to row/bottom
      setTimeout(() => {
        bottomRef?.current!.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    },
    [activeTab, dispatch],
  );

  // CONVERT PATTERN
  const onConvert = useCallback(
    (rows: RowItem[]) => {
      dispatch(onAddNewRow({ row: rows, activeTab, multiple: true }));

      // scroll to row/bottom
      setTimeout(() => {
        bottomRef?.current!.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    },
    [activeTab, dispatch],
  );

  // DELETE
  const handleDeleteRow = (id: UniqueIdentifier) => {
    dispatch(onDeleteRow({ id, activeTab }));
  };

  // ROW CHANGE
  const handleRowChange = useCallback(
    (e: InputChangeEvent, index: number, field: 'label' | 'value' | 'width') => {
      const fieldVal = e.target.value;

      dispatch(onRowUpdate({ index, field, fieldVal, activeTab }));
    },
    [activeTab, dispatch],
  );

  // ROW CHANGE FROM TEXT EDITOR
  const handleRowChangeFormat = useCallback(
    (index: number, fieldVal: string) => {
      dispatch(onRowUpdate({ index, field: 'value', fieldVal, activeTab }));
    },
    [activeTab, dispatch],
  );
  // ROW CHANGE FROM TEXT EDITOR
  const handleFocus = useCallback((i: number) => {
    setCurrFocus(i);
  }, []);

  // VALUE INPUT
  const getRowValueInput = (row: RowItem, i: number) => {
    const valueProps = getInputProps({ type: 'value', row, i, handleRowChange, handleFocus });

    const props = { row, index: i, handleRowChangeFormat, setCurrFocus, currFocus };
    switch (row.type) {
      case 'header':
        return <Input {...valueProps} className={`row-value no-label header-input`} onFocus={() => setCurrFocus(i)} />;
      case 'image':
        return <ImageItem row={row} i={i} handleRowChange={handleRowChange} />;
      default:
        return <RowValueInput {...props} />;
    }
  };

  let placeholder = '';
  switch (activeTab) {
    case 'specs':
      placeholder = 'Spec name';
      break;
    case 'abbreviations':
      placeholder = 'Abbreviation';
      break;
    default:
      placeholder = 'Row';
  }

  if (!project) return <>no project</>;
  const noRows = project[activeTab].length < 1;

  const editorActionProps: EditorActionsProps = {
    addNewRow,
    deleteMode,
    setDeleteMode,
    onConvert,
  };

  return (
    <>
      {/* ACTION BTNS */}
      <EditorActions {...editorActionProps} />

      <div className={`editor-container`}>
        {/* TAGS */}
        {activeTab === 'specs' && <ProjectTags />}
        {/* DEFAULT VIEW */}
        {project[activeTab].map((row, i) => {
          const labelProps = getInputProps({ type: 'label', row, i, handleRowChange, handleFocus });

          return (
            <div key={i}>
              <AddRowAbove indexBelow={i} />
              <div className="row-display">
                {/* label */}
                {row.type === 'row' && <Checkbox style={{ marginRight: '10px' }} checked={true} disabled />}
                {row.type === 'row' && <Input {...labelProps} className="row-label" />}

                {/* value */}
                {getRowValueInput(row, i)}

                {/* delete */}
                {deleteMode ? (
                  <Button className="delete-btn" onClick={() => handleDeleteRow(row.id)} type="text" icon={<Trash size={20} weight="fill" />} danger />
                ) : (
                  <RowEditorMenu index={i} row={row} />
                )}
              </div>
            </div>
          );
        })}
        {noRows && <Empty msg={`No ${activeTab} added yet`} />}
        {noRows && activeTab === 'abbreviations' && <CopyAbbreviations />}
        <div ref={bottomRef}></div>
      </div>
    </>
  );
}

export default memo(EditorView);

/* REORDER VIEW */

/* {reorder && (
  <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleReorder}>
    <SortableContext items={project[activeTab]} strategy={verticalListSortingStrategy}>
      {project[activeTab].map((row, i) => {
        return <SortableRow key={i} row={row} i={i} />;
      })}
    </SortableContext>
  </DndContext>
)} */

//   SORTABLE ROW
// const SortableRow = ({ row, i }: { row: RowItem; i: number }) => {
//   const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: row.id });
//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition,
//   };

//   return (
//     <div key={i} className="row-display sortable" ref={setNodeRef} style={style} {...attributes} {...listeners}>
//       {row.type === 'row' && <span className="row-label">{row.label}</span>}
//       <span className={`row-value ${row.type !== 'row' ? 'no-label' : ''}`}>{row.value}</span>
//     </div>
//   );
// };

// DRAG REORDER
// const handleReorder = (event: DragEndEvent) => {
//   if (!project) return;

//   const { active, over } = event;
//   const currProject = { ...project };
//   let currRows = [...currProject[activeTab]];
//   if (!over) return;

//   if (active.id !== over.id) {
//     const oldIndex = currRows.map(r => r.id).indexOf(active.id);
//     const newIndex = currRows.map(r => r.id).indexOf(over.id);
//     const moved = arrayMove(currRows, oldIndex, newIndex);
//     currProject[activeTab] = moved;
//   }
// };

// const sensors = useSensors(
//   useSensor(PointerSensor),
//   useSensor(KeyboardSensor, {
//     coordinateGetter: sortableKeyboardCoordinates,
//   }),
// );
