import { DotsThreeOutlineVertical, Folder as FolderIcon, Yarn } from '@phosphor-icons/react';
import { Button, Flex, Dropdown, MenuProps } from 'antd';
import { Folder, Project, ProjectListItemProps } from '../../../types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePageLocation from '../../../utils/hooks/usePageLocation';
import getUniqueId from '../../../utils/getUniqueId';
import { DeleteItemModal, MoveToFolderModal } from '../../modals';
import RenameFolder from '../../modals/RenameFolder';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../state/store';
import { onUpdateProject } from '../../../state/project/services';

export default function ProjectListItem({ item, isFolder }: ProjectListItemProps) {
  const navigate = useNavigate();
  const { onFolder } = usePageLocation();
  const user = useSelector((state: RootState) => state.auth.userData?.user);
  const message = useSelector((state: RootState) => state.project.message);
  const dispatch = useDispatch<AppDispatch>();

  const [modalType, setModalType] = useState('');
  const [listItem] = useState<Project | Folder>(item);

  // REMOVE FOLDER
  function removeFromFolder() {
    const updated: Project = { ...item } as Project;
    delete updated.folder;
    dispatch(onUpdateProject({ project: updated, user: user, type: 'save' }));
  }

  // DUPLICATE PROJECT
  function duplicateProject() {
    const duplicate: Project = { ...listItem, name: `${listItem.name} (copy)`, id: getUniqueId() } as Project;
    dispatch(onUpdateProject({ project: duplicate, user, type: 'add' }));
  }

  const navigateTo = () => {
    const project = item as Project;
    if (isFolder) {
      navigate(`folder/${item.id}`);
    } else if (project.folder) {
      navigate(`/my-projects/folder/${project.folder}/${item.id}`);
    } else {
      navigate(`view/${item.id}`);
    }
  };

  // MENU ITEMS
  const items: MenuProps['items'] = [];
  if (isFolder) {
    items.push({
      key: 'rename',
      label: 'Rename',
      onClick: () => setModalType('renameFolder'),
    });
  } else {
    if (onFolder) {
      items.push({
        key: 'remove',
        label: 'Remove from folder',
        onClick: removeFromFolder,
      });
    } else {
      items.push({
        key: 'move',
        label: 'Move to folder',
        onClick: () => setModalType('moveToFolder'),
      });
    }
    items.push({
      key: 'duplicate',
      label: 'Duplicate',
      onClick: duplicateProject,
    });
  }
  items.push({
    key: 'delete',
    label: 'Delete',
    onClick: () => setModalType('delete'),
  });

  useEffect(() => {
    if (!message) {
      setModalType('');
    }
  }, [message]);

  return (
    <Flex className="thumbnail" justify="space-between" align="center">
      <Flex onClick={navigateTo} className="icon-name">
        {isFolder ? <FolderIcon weight="fill" size={20} /> : <Yarn weight="fill" size={20} />}
        <span>{item.name}</span>
      </Flex>

      {/* MENU */}
      <Dropdown menu={{ items }}>
        <Button icon={<DotsThreeOutlineVertical size={16} weight="fill" />} shape="circle" type="text" />
      </Dropdown>

      {/* MODALS (move, rename, delete) */}
      {modalType === 'moveToFolder' && <MoveToFolderModal project={item as Project} />}
      {modalType === 'renameFolder' && <RenameFolder folder={item as Folder} />}
      {modalType === 'delete' && <DeleteItemModal item={item} isFolder={isFolder} />}
    </Flex>
  );
}
