import { Flex, InputNumber, Slider, SliderSingleProps, Upload, UploadProps } from 'antd';
import { InputChangeEvent, RowItem } from '../../../types';
import { storage } from '../../../firebase';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useState } from 'react';
import { Plus } from '@phosphor-icons/react/dist/ssr';
import { RootState } from '../../../state/store';
import { useSelector } from 'react-redux';

type ImageItemProps = {
  row: RowItem;
  handleRowChange: (e: InputChangeEvent, i: number, field: 'label' | 'value' | 'width') => void;
  i: number;
};

export default function ImageItem({ row, i, handleRowChange }: ImageItemProps) {
  const user = useSelector((state: RootState) => state.auth.userData?.user);
  const [imgWidth, setImgWidth] = useState(row.width || 100);

  const uploadProps: UploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (user) {
        const file = info.file.originFileObj as Blob;
        const imgPath = `images/${user.id}/${info.file.name}`;
        const storageRef = ref(storage, imgPath);

        uploadBytesResumable(storageRef, file).then(snapshot => {
          const e: InputChangeEvent = { target: { value: `images/${user?.id}/${info.file.name}` } } as InputChangeEvent;
          handleRowChange(e, i, 'value');
        });
      }
    },
  };

  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = value => `${value}%`;

  const onWidthChange = (width: number) => {
    setImgWidth(width);
    const e: InputChangeEvent = { target: { value: `${width}` } } as InputChangeEvent;
    handleRowChange(e, i, 'width');
  };

  return row.value ? (
    <div style={{ width: '100%' }}>
      <Flex align="center" justify="space-between">
        <Slider min={1} max={100} onChange={onWidthChange} value={typeof imgWidth === 'number' ? imgWidth : 0} tooltip={{ formatter }} style={{ width: '95%' }} />

        <InputNumber
          min={1}
          max={100}
          style={{ marginLeft: '1%' }}
          value={imgWidth}
          size="small"
          suffix="%"
          onChange={w => {
            if (w) onWidthChange(w);
          }}
        />
      </Flex>
      <div className="row-image" style={{ width: `${imgWidth}%` }}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/tmdhcily.appspot.com/o/${row.value.replaceAll('/', '%2F')}?alt=media`} alt="" />
      </div>
    </div>
  ) : (
    <Upload {...uploadProps} name="avatar" listType="picture-card" className="avatar-uploader">
      <button style={{ border: 0, background: 'none' }} type="button">
        <Flex align="center">
          <Plus size={16} style={{ marginRight: '5px' }} /> Upload Image
        </Flex>
      </button>
    </Upload>
  );
}
