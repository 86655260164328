import '../styles/Global.scss';
import '../styles/MyProjects.scss';
import { useMemo, useState } from 'react';
import { Folder, Project, ProjectListItemProps, ResponseData, SortBy } from '../types';
import { SearchProjects } from '../components/project/index';
import { Flex } from 'antd';
import Empty from '../components/global/Empty';
import { filterProjects, sortItems } from '../utils/sortFilter';
import { useParams } from 'react-router-dom';
import { ProjectListItem } from '../components/project/components';
import { useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { useGetUserDataQuery } from '../state/auth/userDataApi';
import Loading from '../components/global/Loading';

export default function MyProjects({ folderView }: { folderView?: boolean }) {
  const { userData } = useSelector((state: RootState) => state.auth);
  let { folderId } = useParams();
  const { isLoading } = useGetUserDataQuery({});

  const { allProjects, allFolders } = userData as ResponseData;
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<SortBy>({ type: 'lastModified', desc: true });
  const [tagFilters, setTagFilters] = useState<string[]>([]);

  // PROJECTS
  const filteredProjects: Project[] = useMemo(() => {
    let projects = allProjects ? [...allProjects] : [];

    // on folder view
    if (folderView) {
      const folder: Folder | null = allFolders.find((f: Folder) => f.id === folderId) || null;
      if (!folder) {
        return [];
      } else {
        projects = allProjects.filter((p: Project) => p?.folder === folderId);
      }
    }

    // sort
    let filtered = sortItems({ items: projects, sortBy }) as Project[];
    // tag filter
    if (tagFilters?.length) {
      filtered = filterProjects({ projects, tagFilters });
    }
    // search
    if (search.length < 1) {
      if (!folderView && tagFilters?.length < 1) {
        return filtered?.filter((p: Project) => !p.folder);
      } else {
        return filtered;
      }
    } else {
      return filtered.filter((p: Project) => p.name.toLowerCase().trim().includes(`${search}`));
    }
  }, [allProjects, search, sortBy, tagFilters, allFolders, folderId, folderView]);

  // FOLDERS
  const filteredFolders: Folder[] = useMemo(() => {
    // sort
    let filtered = sortItems({ items: allFolders, sortBy }) as Folder[];
    //search
    if (search.length < 1) {
      return [...filtered];
    } else {
      return filtered.filter((f: Folder) => f.name.toLowerCase().trim().includes(`${search}`));
    }
  }, [allFolders, search, sortBy]);

  const hideFolders = tagFilters.length > 0 || folderView;

  // SEARCH PROPS
  const searchProps = { sortBy, setSortBy, setTagFilters, setSearch };

  return (
    <>
      <SearchProjects {...searchProps} />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container">
          {!hideFolders && filteredFolders.length > 0 && (
            <div className="thumbnail-container">
              <p className="thumbnail-category">Folders</p>
              <Flex wrap>
                {/* FOLDERS */}
                {!hideFolders &&
                  filteredFolders.map((folder: Folder) => {
                    const props: ProjectListItemProps = { item: folder, allFolders, isFolder: true };
                    return <ProjectListItem {...props} key={folder.id} />;
                  })}
              </Flex>
            </div>
          )}

          {filteredProjects.length > 0 && !folderView && (
            <div className="thumbnail-container">
              <p className="thumbnail-category">Projects</p>
              <Flex wrap>
                {/* PROJECTS  */}
                {filteredProjects.map((project: Project) => {
                  const props: ProjectListItemProps = { item: project, allFolders };
                  return <ProjectListItem {...props} key={project.id} />;
                })}
              </Flex>
            </div>
          )}

          {!hideFolders && !filteredProjects.length && !filteredFolders.length && <Empty msg="No projects or folders found" />}
          {hideFolders && !filteredProjects.length && <Empty msg="No projects  found" />}
        </div>
      )}
    </>
  );
}
